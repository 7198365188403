
import { Vue, Component } from 'vue-property-decorator'

import AppFooter from '~/components/footer/footer.vue'

@Component({
  components: {
    AppFooter
  }
})
export default class Default extends Vue {
  get isLogin () {
    return this.$route.path === '/login'
  }

  get mainDivStyle () {
    return this.isLogin ? { backgroundColor: '#b51a23 !important;' } : {}
  }
}

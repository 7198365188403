import { Component, Vue, Prop } from 'vue-property-decorator'
import { ApiPocessState, UpdatePassword } from '~/types'
import { EVENT_BUS } from '~/plugins/eventBus'

@Component({
  name: 'app-user-profile-form'
})
export default class AppUserProfileForm extends Vue {
  @Prop() formType!: string
  @Prop() pageType!: string
  @Prop() storePath!: string
  @Prop() closeModalEvent!: string
  @Prop() apiState!: ApiPocessState
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  apiResponseMessage: string = ''
  apiProcessCompleted: boolean = false

  currentPassword: string = ''
  password: string = ''
  confirmPassword: string = ''

  processing: boolean = false
  // Error properties
  error: boolean = false

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Computed Methods
  // --------------------------------------------------------------------------

  get processedState () {
    const userProcess = 'update'
    this.apiProcessCompleted = this.apiState.completed

    switch (this.apiState.statusCode) {
      case 200:
        this.apiResponseMessage = `User password successfully ${userProcess}d`
        break
      default:
        this.apiResponseMessage = `User password ${userProcess} operation failed.`
        break
    }
    setTimeout(() => {
      this.apiProcessCompleted = false
      this.apiResponseMessage = ''
    }, 10000)
    return JSON.parse(JSON.stringify(this.apiState))
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  async onFormSubmit () {
    let apiRequest: UpdatePassword
    this.processing = true

    if (!await this.isFormValid()) {
      this.processing = false
      return
    }

    try {
      apiRequest = {
        currentPassword: this.currentPassword,
        password: this.password
      }

      this.$store.dispatch('updateAccountPassword', apiRequest)
    } catch (error: any) {
      console.log(error)
    } finally {
      this.processing = false
    }
  }

  closeMessge () {
    this.apiProcessCompleted = false
    this.apiResponseMessage = ''
  }

  closeModal () {
    this.apiProcessCompleted = false
    this.apiResponseMessage = ''
    const params = false
    this.$store.commit('resetAPIProcessState', null, { root: true })
    EVENT_BUS.$emit(this.closeModalEvent, params)
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
  private isFormValid (): boolean {
    return (this as any).$refs.userProfileForm.validate().then((passedValidation: boolean) => {
      return passedValidation
    })
  }

  // --------------------------------------------------------------------------
  // [Private] Lifecycle Hooks
  // --------------------------------------------------------------------------
}


import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment-timezone'

@Component({
  name: 'app-footer'
})
export default class AppFooter extends Vue {
  year = moment().format('YYYY')

  get isLogin () {
    return this.$route.path === '/login'
  }
}

import { Component, Vue, PropSync, Emit } from 'vue-property-decorator'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

@Component({
  name: 'GkvrButton',
  components: {
    ClipLoader
  }
})
export default class GkvrButton extends Vue {
  @PropSync('id') idValue!: string
  @PropSync('classes', { type: String, default: '' }) classValue!: string
  @PropSync('type', { type: String, default: 'button' }) typeValue!: string
  @PropSync('loading', { type: Boolean, default: false }) loadingValue!: boolean
  @PropSync('disabled', { type: Boolean, default: false }) disabledValue!: boolean

  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  // eslint-disable-next-line no-useless-constructor
  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Computed Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  @Emit('click')
  onButtonClick (e: any) {
    return e
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Lifecycle Hooks
  // --------------------------------------------------------------------------
}

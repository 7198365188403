import { Reward } from './rewards'
import { PageState, SortState } from './state'

export interface PromoCode {
  id: number;
  spoonityCode: string;
  shortCode: string;
  promoName: string;
  pointsValue: number;
  startDate: string;
  endDate: string;
  requiresPhone: boolean;
  reward?: Reward;
}

export type PromoCodeRequestBody = Omit<PromoCode, 'id' | 'reward' | 'requiresPhone'> & {
  rewardId?: number;
  id?: number;
};

// export type PromoCodeUpdateRequestBody = Omit<PromoCodeRequestBody, "shortCode" | "spoonityCode">

export type PromoCodeWithRewardName = Omit<PromoCode, 'reward'> & {
  rewardName?: string;
}

export interface InitPromoCodeState {
  promoCodes: PromoCode[];
  currentPromoCodes: PromoCode[];
  sort: SortState;
  pageStatus: PageState;
  filter: PromoCodeFilterState;
}

export interface PromoCodeFilterState {
  promoName: string;
  shortCode: string;
  spoonityCode: string;
  startDate: string;
  endDate: string;
  dateBy: string;
}

export enum PromoCodeDateBy {
  CREATED = 'created',
  MODIFIED = 'modified',
  ACTIVE = 'active',
}

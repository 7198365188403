import Axios, { AxiosResponse } from 'axios'
import { TokenService } from './token.service'
import { BaseService } from './base.service'
import { CreatedUser } from '~/types/users'

class UserService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  toUrlEncoded = (obj: any) => {
    return Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
  }

  public async createUser (createUserRequest: CreatedUser): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.post(`${apiHost}/api/v1/users`, createUserRequest, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async editUser (id: number | undefined, editUserRequest: CreatedUser): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.put(`${apiHost}/api/v1/users/${id}`, editUserRequest, config)
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async deleteUser (id: number): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.delete(`${apiHost}/api/v1/users/${id}`, config)
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async getAllUsers (): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/users`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async searchUsers (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/users/search?shouldPage=true&size=100&${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async lookupCustomer (accountNumber:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/users/lookupCustomer?accountNumber=${accountNumber}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async loadCsv (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/users/search?${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
};

export default UserService

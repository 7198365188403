import AccountService from './account.service'
import CategoryService from './rewardCategory.service'
import UserService from './user.service'
import RewardService from './reward.service'
import PartnerService from './partner.service'
import VoucherService from './voucher.service'
import StoreService from './store.service'
import PromoCodeService from './promo-code.service'
import PromoClaimService from './promo-claim.service'
import EmailTriggerService from './email-trigger.service'

const accountService = new AccountService()
const categoryService = new CategoryService()
const rewardService = new RewardService()
const userService = new UserService()
const partnerService = new PartnerService()
const voucherService = new VoucherService()
const promoCodeService = new PromoCodeService()
const promoClaimService = new PromoClaimService()
const storeService = new StoreService()
const emailTriggerService = new EmailTriggerService()

export function setupServices (configs: any): void {
  accountService.configs = configs
  categoryService.configs = configs
  partnerService.configs = configs
  userService.configs = configs
  rewardService.configs = configs
  voucherService.configs = configs
  storeService.configs = configs
  promoCodeService.configs = configs
  promoClaimService.configs = configs
  emailTriggerService.configs = configs
}

export {
  accountService as AccountService,
  categoryService as CategoryService,
  partnerService as PartnerService,
  userService as UserService,
  rewardService as RewardService,
  voucherService as VoucherService,
  storeService as StoreService,
  promoCodeService as PromoCodeService,
  promoClaimService as PromoClaimService,
  emailTriggerService as EmailTriggerService
}

const axios = require('axios')

const toUrlEncoded = (obj) => {
  return Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
}

export default async function (req, res) {
  let result = null
  const apiHost = process.env.API_HOST

  req.on('data', async (chunk) => {
    const body = JSON.parse(chunk)

    const config = {
      headers: {
        Authorization: `Basic ${Buffer.from(`${process.env.CLIENT_ID}:${process.env.CLIENT_SECRET}`).toString('base64')}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    let response

    try {
      result = await axios.post(`${apiHost}/oauth/token`, toUrlEncoded(body), config)
      response = {
        passed: true,
        data: result.data,
        status: result.status
      }
    } catch (error) {
      response = {
        passed: false,
        error: 'authentication failed, please contact admin'
      }
    }

    res.end(JSON.stringify(response))
  })
}

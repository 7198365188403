import { GetterTree, ActionTree, MutationTree } from 'vuex'
import moment from 'moment-timezone'
import { RootState, PageState, SortState } from '~/types/state'
import { InitRewardCategoryState, RewardCategory, RewardCategoryUpdate, UpdateRewardCategoryState, RewardCategoryFilterState } from '~/types/rewardCategories'
import { CategoryService } from '~/services'

export const state = (): InitRewardCategoryState => ({
  rewardCategories: [],
  currentRewardCategories: [],
  filter: {
    term: ''
  },
  sort: {
    sortfield: 'name',
    order: 'desc'
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0
  }
})

export const getters: GetterTree < InitRewardCategoryState, RootState > = {
  rewardCategories: state => ((state.rewardCategories).filter(f => !f.isDeleted ? f : false).map((elem: RewardCategory) => {
    return ({
      id: elem.id ? elem.id : 0,
      name: elem.name,
      image: elem.image,
      shortName: elem.shortName,
      isDeleted: elem.isDeleted ? elem.isDeleted : false,
      createdOn: elem.createdOn === null ? '' : moment(elem.createdOn).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.updatedOn === null ? '' : moment(elem.updatedOn).tz('Jamaica', true).format('MMM-DD-YY')
    })
  })),
  rewardCategoriesDropDown: state => ((state.rewardCategories).filter(f => !f.isDeleted ? f : false).map((elem: RewardCategory) => {
    return ({
      id: elem.id ? elem.id : 0,
      name: elem.name
    })
  })).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1),
  currentRewardCategories: state => ((state.currentRewardCategories).filter(f => !f.isDeleted ? f : false).map((elem: RewardCategory) => {
    return ({
      id: elem.id ? elem.id : 0,
      name: elem.name,
      image: elem.image,
      shortName: elem.shortName,
      isDeleted: elem.isDeleted ? elem.isDeleted : false,
      createdOn: elem.createdOn === null ? '' : moment(elem.createdOn).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.updatedOn === null ? '' : moment(elem.updatedOn).tz('Jamaica', true).format('MMM-DD-YY')
    })
  })),
  pageStatus: (state) => {
    return state.pageStatus
  }
}

export const mutations: MutationTree < InitRewardCategoryState > = {
  setRewardCategories (state: InitRewardCategoryState, rewardCategories: Array < RewardCategory >): void {
    state.rewardCategories = [...rewardCategories]
  },
  setCurrentRewardCategories (state: InitRewardCategoryState, currentRewardCategories: Array < RewardCategory >): void {
    state.currentRewardCategories = [...currentRewardCategories]
  },
  reset (state: InitRewardCategoryState): void {
    state.rewardCategories = []
    state.currentRewardCategories = []
    state.pageStatus.pageNumber = 1
    state.pageStatus.pageSize = 0
    state.pageStatus.totalPages = 0
    state.pageStatus.total = 0
  },
  setFilterStatus (state: InitRewardCategoryState, status: RewardCategoryFilterState): void {
    state.filter.term = status.term
  },
  setSortStatus (state: InitRewardCategoryState, status: SortState): void {
    state.sort.sortfield = status.sortfield
    state.sort.order = status.order
  },
  setPageStatus (state: InitRewardCategoryState, status: PageState): void {
    state.pageStatus.pageNumber = status.pageNumber
    state.pageStatus.pageSize = status.pageSize
    state.pageStatus.totalPages = status.totalPages
    state.pageStatus.total = status.total
  }
}

export const actions: ActionTree < InitRewardCategoryState, RootState > = {

  async createRewardCategories ({ commit, dispatch, state }, createRequest: RewardCategoryUpdate) {
    commit('processInProgress', null, { root: true })
    try {
      const result = await CategoryService.createRewardCategory(createRequest)
      if (result.status === 200) {
        await dispatch('loadCurrentRewardCategories', { pageStatus: state.pageStatus, sort: state.sort })
        commit('processSuccess', result.status, { root: true })
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async updateRewardCategories ({ commit, state }, data: UpdateRewardCategoryState) {
    commit('processInProgress', null, { root: true })

    const currentRewardCategories = state.currentRewardCategories.slice()
    try {
      const result = await CategoryService.editRewardCategory(data.id, data.category)

      if (result.status === 200) {
        if (result.data !== undefined) {
          const list = currentRewardCategories.map((elem:RewardCategory) => elem.id === result.data.id ? elem = result.data : elem)
          commit('processSuccess', result.status, { root: true })
          commit('setCurrentRewardCategories', list)
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async deleteRewardCategories ({ commit, state }, data:any) {
    commit('processInProgress', null, { root: true })
    const currentRewardCategories = state.currentRewardCategories.slice()
    try {
      const result = await CategoryService.deleteRewardCategory(data.id)

      if (result.status === 200) {
        const list = currentRewardCategories.filter((elem:RewardCategory) => elem.id === data.id ? false : elem)
        commit('processSuccess', result.status, { root: true })
        commit('setCurrentRewardCategories', list)
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadRewardCategories ({ commit }) {
    commit('processInProgress', null, { root: true })
    const result = await CategoryService.getAllRewardCategories()
    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setRewardCategories', result.data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setRewardCategories', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadCurrentRewardCategories ({ commit, state }, pageNumber) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${state.sort.sortfield},${state.sort.order}
            &page=${pageNumber ? (pageNumber - 1) : (state.pageStatus.pageNumber - 1)}`).trim().replace(/\s+(\&)/g, '&')

      const result = await CategoryService.searchRewardCategories(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentRewardCategories = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          commit('setPageStatus', pageStatus)
          commit('setCurrentRewardCategories', currentRewardCategories)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentRewardCategories', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async orderRewardCategories ({ commit, state }, data: SortState) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${data.sortfield},${data.order}
            &page=${(state.pageStatus.pageNumber - 1)}`).trim().replace(/\s+(\&)/g, '&')

      const result = await CategoryService.searchRewardCategories(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentRewardCategories = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          const sortStatus = {
            sortfield: data.sortfield,
            order: data.order
          }
          commit('setSortStatus', sortStatus)
          commit('setPageStatus', pageStatus)
          commit('setCurrentRewardCategories', currentRewardCategories)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentRewardCategories', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async searchRewardCategories ({ commit }, filter: RewardCategoryFilterState) {
    commit('processInProgress', null, { root: true })
    const search = (`${filter.term === '' || filter.term === undefined ? '' : `query=${filter.term}`}`).trim().replace(/\s+(\&)/g, '&')
    const result = await CategoryService.searchRewardCategories(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.results.length > 0) {
        const currentRewardCategories = result.data.results
        const status = {
          term: filter.term
        }
        const pageStatus = {
          pageNumber: (result.data.currentPageable.pageNumber + 1),
          pageSize: result.data.currentPageable.pageSize,
          totalPages: result.data.totalPages,
          total: result.data.totalNumberOfElements
        }
        commit('setPageStatus', pageStatus)
        commit('setFilterStatus', status)
        commit('setCurrentRewardCategories', currentRewardCategories)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setCurrentRewardCategories', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
      commit('processSuccess', result.status, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  },

  async loadCsv ({ commit, state }) {
    commit('processInProgress', null, { root: true })
    const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}`).trim().replace(/\s+(\&)/g, '&')
    const result = await CategoryService.loadCsv(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.length > 0) {
        const data = result.data
        commit('setRewardCategories', data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setRewardCategories', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
      commit('processSuccess', result.status, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  }
}

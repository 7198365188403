import { TokenService } from './token.service'

export abstract class BaseService {
  configs: any = {}

  protected get headers () {
    return {
      Authorization: `Bearer ${TokenService.getToken()}`,
      'Content-Type': 'application/json'
    }
  }
}

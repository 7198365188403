import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState, PageState, SortState } from '~/types/state'
import { InitPromoCodeState, PromoCode, PromoCodeFilterState, PromoCodeRequestBody } from '~/types/promoCode'
import { PromoCodeService } from '~/services'

export const state = (): InitPromoCodeState => ({
  promoCodes: [],
  currentPromoCodes: [],
  filter: {
    promoName: '',
    shortCode: '',
    spoonityCode: '',
    startDate: '',
    endDate: '',
    dateBy: ''
  },
  sort: {
    sortfield: 'id',
    order: 'desc'
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0
  }
})

export const getters: GetterTree<InitPromoCodeState, RootState> = {
  promoCodes: state => state.promoCodes,
  filter: state => state.filter,
  sort: state => state.sort,
  pageStatus: state => state.pageStatus
}

export const mutations: MutationTree<InitPromoCodeState> = {
  setPromoCodes (state, promoCodes: PromoCode[]) {
    state.promoCodes = [...promoCodes]
  },
  setCurrentPromoCodes (state, promoCodes: PromoCode[]) {
    state.currentPromoCodes = [...promoCodes]
  },
  setSortStatus (state, status: SortState): void {
    state.sort = { ...status }
  },
  setPageStatus (state, status: PageState): void {
    state.pageStatus = { ...status }
  },
  setFilterStatus (state, filter: PromoCodeFilterState): void {
    state.filter = { ...filter }
  }
}

export const actions: ActionTree<InitPromoCodeState, RootState> = {

  async loadPromoCodes ({ commit, state }, data: {pageNumber: number}) {
    commit('processInProgress', null, { root: true })

    const { sort, filter, pageStatus } = state

    const updatedPageStatus: PageState = { ...pageStatus, pageNumber: (data && data.pageNumber) ? data.pageNumber : pageStatus.pageNumber }
    const search = getPromoCodeSearchString(filter, sort, updatedPageStatus)

    const result = await PromoCodeService.searchPromoCodes(search)

    if (result.success) {
      const newPageStatus: PageState = {
        pageNumber: result.data.currentPageable.pageNumber + 1,
        pageSize: result.data.currentPageable.pageSize,
        totalPages: result.data.totalPages,
        total: result.data.totalNumberOfElements
      }

      commit('setPromoCodes', result.data.results)
      commit('processSuccess', 204, { root: true })
      commit('setPageStatus', newPageStatus)
      commit('resetAPIProcessState', null, { root: true })
    } else {
      commit('processError', result.error.error.message, { root: true })
    }
  },

  async createPromoCode ({ commit, dispatch, state }, data: PromoCodeRequestBody) {
    commit('processInProgress', null, { root: true })

    const result = await PromoCodeService.createPromoCode(data)
    if (result.success) {
      await dispatch('loadPromoCodes', { pageStatus: state.pageStatus, sort: state.sort })
      commit('processSuccess', 200, { root: true })
    } else {
      commit('processError', result.error.error.message, { root: true })
    }
  },

  async updatePromoCode ({ commit, dispatch, state }, data: PromoCodeRequestBody) {
    commit('processInProgress', null, { root: true })

    const result = await PromoCodeService.updatePromoCode(data.shortCode, data)
    if (result.success) {
      await dispatch('loadPromoCodes', { pageStatus: state.pageStatus, sort: state.sort })
      commit('processSuccess', 200, { root: true })
    } else {
      commit('processError', result.error.error, { root: true })
    }
  },

  async searchPromoCodes ({ commit, state }, filter: PromoCodeFilterState) {
    commit('processInProgress', null, { root: true })

    const { pageStatus, sort } = state
    const search = getPromoCodeSearchString(filter, sort, pageStatus)

    const result = await PromoCodeService.searchPromoCodes(search[0] === '&' ? search.substring(1) : search)

    if (result.success) {
      const newPageStatus: PageState = {
        pageNumber: result.data.currentPageable.pageNumber + 1,
        pageSize: result.data.currentPageable.pageSize,
        totalPages: result.data.totalPages,
        total: result.data.totalNumberOfElements
      }

      const promoCodes = result.data.results
      commit('setPromoCodes', promoCodes)
      commit('setFilterStatus', filter)
      commit('setPageStatus', newPageStatus)
      commit('processSuccess', 200, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    } else {
      commit('processError', result.error.error, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  },

  async deletePromoCode ({ commit, dispatch, state }, shortCode: string) {
    commit('processInProgress', null, { root: true })

    const result = await PromoCodeService.deletePromoCode(shortCode)
    if (result.success) {
      // TODO: add pagination and sor
      await dispatch('loadPromoCodes', { pageStatus: state.pageStatus, sort: state.sort })
      commit('processSuccess', 200, { root: true })
    } else {
      commit('processError', result.error.error, { root: true })
    }
  },

  async loadCsv ({ commit, state }) {
    commit('processInProgress', null, { root: true })
    const { filter, sort, pageStatus } = state

    const search = getPromoCodeSearchString(filter, sort, pageStatus)

    const result = await PromoCodeService.searchPromoCodes(search, false)
    if (result.success) {
      const promoCodes = result.data
      commit('setPromoCodes', promoCodes)
      commit('processSuccess', 200, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    } else {
      commit('processError', result.error.error, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  },

  async orderPromoCodes ({ commit, state }, data: SortState) {
    commit('processInProgress', null, { root: true })

    const { filter, pageStatus } = state
    const search = getPromoCodeSearchString(filter, data, pageStatus)

    const result = await PromoCodeService.searchPromoCodes(search)
    if (result.success) {
      const promoCodes = result.data
      commit('setPromoCodes', promoCodes)
      commit('setSortStatus', data)
      commit('processSuccess', 200, { root: true })
      commit('resetAPIProcessState', null, { root: true })
      // TODO: add pageStatus update
    } else {

    }
  }

}

function getPromoCodeSearchString (filter: PromoCodeFilterState, sort: SortState, pageStatus: PageState): string {
  const page = pageStatus.pageNumber - 1

  return `${filter.dateBy ? `dateBy=${filter.dateBy}` : ''}
  &sort=${sort.sortfield},${sort.order}
  &page=${page}
  ${filter.promoName ? `&promoName=${filter.promoName}` : ''}
  ${filter.shortCode ? `&shortCode=${filter.shortCode}` : ''}
  ${filter.spoonityCode ? `&spoonityCode=${filter.spoonityCode}` : ''}
  ${filter.startDate ? `&startDate=${filter.startDate}` : ''}
  ${filter.endDate ? `&endDate=${filter.endDate}` : ''}`
    .trim().replace(/\s+(\&)/g, '&')
}

import Axios, { AxiosResponse } from 'axios'
import { TokenService } from './token.service'
import { BaseService } from './base.service'
import { VoucherUpdate } from '~/types/vouchers'

class VoucherService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  toUrlEncoded = (obj: any) => {
    return Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
  }

  public async getAllVouchers (): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async searchVouchers (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers/search?shouldPage=true&size=100&${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async resendVoucher (id:number): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    const body = {}

    try {
      result = await Axios.post(`${apiHost}/api/v1/vouchers/${id}/resend`, body, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async editVoucher (id: number | undefined, editVoucherRequest: VoucherUpdate): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.put(`${apiHost}/api/v1/vouchers/${id}`, editVoucherRequest, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  // --------------------------------------------------------------------------
  // Voucher Approval
  // --------------------------------------------------------------------------

  public async findVoucher (gkvr_no: string, voucher_no: string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers/lookup?accountNumber=${gkvr_no}&certificateId=${voucher_no}`, config)
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  // --------------------------------------------------------------------------
  // Voucher Details
  // --------------------------------------------------------------------------

  public async getAllVoucherDetails (voucher_no:number): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers/search?voucherNumber=${voucher_no}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async searchVoucherDetails (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers/search?shouldPage=true&size=100&${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  // --------------------------------------------------------------------------
  // Voucher Summary
  // --------------------------------------------------------------------------

  public async getAllVoucherSummary (): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async searchVoucherSummary (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers/summary?shouldPage=true&size=100&${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async loadCsv (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers/search?${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async loadCsvSummary (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/vouchers/summary?${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
};

export default VoucherService

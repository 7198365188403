import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/types/state'
import { InitEmailTriggerState, EmailTrigger } from '~/types/emailTriggers'
import { EmailTriggerService } from '~/services'

export const state = (): InitEmailTriggerState => ({
  emailTriggers: []
})

export const getters: GetterTree <InitEmailTriggerState, RootState> = {
  emailTriggers: state => (state.emailTriggers).map((elem: EmailTrigger) => {
    return ({
      id: elem.id,
      triggerId: elem.triggerId,
      triggerName: elem.triggerName,
      isEnabled: elem.isEnabled
    })
  })
}

export const mutations: MutationTree < InitEmailTriggerState > = {
  setEmailTriggers (state: InitEmailTriggerState, emailTriggers: Array <EmailTrigger>): void {
    state.emailTriggers = [...emailTriggers]
  },
  reset (state: InitEmailTriggerState): void {
    state.emailTriggers = []
  }
}

export const actions: ActionTree < InitEmailTriggerState, RootState > = {
  async updateEmailTriggers ({ commit, state }) {
    commit('processInProgress', null, { root: true })
    try {
      const result = await EmailTriggerService.toggleEmailTriggers(state.emailTriggers)

      if (result.status === 200) {
        commit('processSuccess', result.status, { root: true })
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadEmailTriggers ({ commit }) {
    commit('processInProgress', null, { root: true })
    const result = await EmailTriggerService.getEmailTriggers()
    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setEmailTriggers', result.data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('setEmailTriggers', [])
      commit('processSuccess', 204, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  },

  updateEmailTrigger ({ commit, state }, data: EmailTrigger) {
    const updatedEmailTriggers = state.emailTriggers.map((trigger) => {
      if (trigger.triggerId === data.triggerId) {
        return { ...trigger, isEnabled: data.isEnabled }
      }
      return trigger
    })
    commit('setEmailTriggers', updatedEmailTriggers)
  }
}

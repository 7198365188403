import { GetterTree, ActionTree, MutationTree } from 'vuex'
import moment from 'moment-timezone'
import { RootState, PageState, SortState } from '~/types/state'
import { InitVoucherDetailsState, Voucher, VoucherDetailsFilterState, VoucherInitSortState } from '~/types/vouchers'
import { VoucherService } from '~/services'

const SYSTEM_NAME = 'System'

export const state = (): InitVoucherDetailsState => ({
  voucherDetails: [],
  currentVoucherDetails: [],
  filter: {
    voucher_no: '',
    filter: {
      partnerId: 0,
      reward: 0,
      status: '',
      dateBy: '',
      startDate: '',
      endDate: '',
      store: 0
    }
  },
  sort: {
    sortfield: VoucherInitSortState.SORT_FIELD,
    order: VoucherInitSortState.SORT_ORDER_DESC
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 20,
    totalPages: 0,
    total: 0
  }
})

export const getters: GetterTree < InitVoucherDetailsState, RootState > = {
  voucherDetails: state => ((state.voucherDetails).map((elem: Voucher) => {
    return ({
      id: elem.voucherNumber,
      reward: elem.reward.name,
      certificateId: elem.certificateId,
      firstName: `${elem.changes.length > 0 ? elem.changes[0].account.firstName : SYSTEM_NAME}`,
      lastName: `${elem.changes.length > 0 ? elem.changes[0].account.lastName : SYSTEM_NAME}`,
      status: elem.status,
      redeemedBy: elem.redeemedBy,
      redemptionDate: elem.redemptionDate === null ? '' : moment(elem.redemptionDate).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.changes.length > 0 ? moment(elem.changes[0].date).tz('Jamaica', true).format('MMM-DD-YY, h:mm:ss a') : (elem.modifiedDate ? moment(elem.modifiedDate).tz('Jamaica', true).format('MMM-DD-YY, h:mm:ss a') : ''),
      changes: elem.changes,
      store: elem.store ? elem.store.name : null,
      partner: elem.partner ? elem.partner.name : null
    })
  })),
  currentVoucherDetails: state => ((state.currentVoucherDetails).map((elem: Voucher) => {
    return ({
      id: elem.voucherNumber,
      reward: elem.reward.name,
      certificateId: elem.certificateId,
      firstName: `${elem.changes.length > 0 ? elem.changes[0].account.firstName : SYSTEM_NAME}`,
      lastName: `${elem.changes.length > 0 ? elem.changes[0].account.lastName : SYSTEM_NAME}`,
      status: elem.status,
      redeemedBy: elem.redeemedBy,
      redemptionDate: elem.redemptionDate === null ? '' : moment(elem.redemptionDate).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.changes.length > 0 ? moment(elem.changes[0].date).tz('Jamaica', true).format('MMM-DD-YY, h:mm:ss a') : (elem.modifiedDate ? moment(elem.modifiedDate).tz('Jamaica', true).format('MMM-DD-YY, h:mm:ss a') : ''),
      changes: elem.changes,
      store: elem.store ? elem.store.name : null,
      storeId: elem.store ? elem.store.id : null,
      partner: elem.partner ? elem.partner.name : null
    })
  })),
  pageStatus: (state) => {
    return state.pageStatus
  },
  filterState: (state) => {
    return state.filter
  }
}

export const mutations: MutationTree < InitVoucherDetailsState > = {
  setVoucherDetails (state: InitVoucherDetailsState, voucherDetails: Array < Voucher >): void {
    state.voucherDetails = [...voucherDetails]
  },
  setCurrentVoucherDetails (state: InitVoucherDetailsState, currentVoucherDetails: Array < Voucher >): void {
    state.currentVoucherDetails = ([...currentVoucherDetails]).map((m) => {
      m.changes.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      return {
        ...m
      }
    })
  },
  reset (state: InitVoucherDetailsState): void {
    state.voucherDetails = []
    state.currentVoucherDetails = []
    state.pageStatus.pageNumber = 1
    state.pageStatus.pageSize = 0
    state.pageStatus.totalPages = 0
    state.pageStatus.total = 0
  },
  setFilterStatus (state: InitVoucherDetailsState, status: VoucherDetailsFilterState): void {
    state.filter.voucher_no = status.voucher_no
    state.filter.filter.partnerId = status.filter.partnerId
    state.filter.filter.reward = status.filter.reward
    state.filter.filter.status = status.filter.status
    state.filter.filter.startDate = status.filter.startDate
    state.filter.filter.endDate = status.filter.endDate
    state.filter.filter.dateBy = status.filter.dateBy
    state.filter.filter.store = status.filter.store
  },
  setSortStatus (state: InitVoucherDetailsState, status: SortState): void {
    state.sort.sortfield = status.sortfield
    state.sort.order = status.order
  },
  setPageStatus (state: InitVoucherDetailsState, status: PageState): void {
    state.pageStatus.pageNumber = status.pageNumber
    state.pageStatus.pageSize = status.pageSize
    state.pageStatus.totalPages = status.totalPages
    state.pageStatus.total = status.total
  }
}

export const actions: ActionTree < InitVoucherDetailsState, RootState > = {

  async loadVoucherDetails ({ commit }, data) {
    commit('processInProgress', null, { root: true })

    const result = await VoucherService.getAllVoucherDetails(data.id)

    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setVoucherDetails', result.data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setVoucherDetails', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadCurrentVoucherDetails ({ commit, state }, data) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`sort=${state.sort.sortfield},${state.sort.order}&page=${data.pageNumber ? (data.pageNumber - 1) : (state.pageStatus.pageNumber - 1)}
            ${state.filter.voucher_no !== '' ? `&certificateId=${state.filter.voucher_no}` : ''}
            ${data.partnerId !== 0 ? `&partner=${data.partnerId}` : ''}
            ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
            ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
            ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
            ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
            ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}
            ${data.storeId ? `&store=${data.storeId}` : ''}`).trim().replace(/\s+(\&)/g, '&')

      const result = await VoucherService.searchVoucherDetails(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.data.results.length > 0) {
          const currentVouchers = result.data.data.results

          const pageStatus = {
            pageNumber: (result.data.data.currentPageable.pageNumber + 1),
            pageSize: result.data.data.currentPageable.pageSize,
            totalPages: result.data.data.totalPages,
            total: result.data.data.totalNumberOfElements
          }
          commit('setPageStatus', pageStatus)
          commit('setCurrentVoucherDetails', currentVouchers)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentVoucherDetails', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async orderVoucherDetails ({ commit, state }, data) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`sort=${data.sortfield},${data.order}&page=${(state.pageStatus.pageNumber - 1)}
            ${state.filter.voucher_no !== '' ? `&certificateId=${state.filter.voucher_no}` : ''}
            ${data.partnerId && data.partnerId !== 0 ? `&partner=${data.partnerId}` : ''}
            ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
            ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
            ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
            ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
            ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}
            ${state.filter.filter.store ? `&store=${state.filter.filter.store}` : ''}`).trim().replace(/\s+(\&)/g, '&')

      const result = await VoucherService.searchVoucherDetails(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.data.results.length > 0) {
          const currentVouchers = result.data.data.results

          const pageStatus = {
            pageNumber: (result.data.data.currentPageable.pageNumber + 1),
            pageSize: result.data.data.currentPageable.pageSize,
            totalPages: result.data.data.totalPages,
            total: result.data.data.totalNumberOfElements
          }
          const sortStatus = {
            sortfield: data.sortfield,
            order: data.order
          }
          commit('setSortStatus', sortStatus)
          commit('setPageStatus', pageStatus)
          commit('setCurrentVoucherDetails', currentVouchers)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentVoucherDetails', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async searchVoucherDetails ({ commit, state }, filter: VoucherDetailsFilterState) {
    commit('processInProgress', null, { root: true })
    const search = (`sort=${state.sort.sortfield},${state.sort.order}
        ${filter.voucher_no !== '' ? `&certificateId=${filter.voucher_no}` : ''}
        ${filter.filter.partnerId !== 0 ? `&partner=${filter.filter.partnerId}` : ''}
        ${filter.filter.reward !== 0 ? `&reward=${filter.filter.reward}` : ''}
        ${filter.filter.startDate != '' ? `&startDate=${filter.filter.startDate}` : ''}
        ${filter.filter.endDate !== '' ? `&endDate=${filter.filter.endDate}` : ''}
        ${filter.filter.dateBy !== '' ? `&dateBy=${filter.filter.dateBy}` : ''}
        ${filter.filter.status !== '' ? `&status=${filter.filter.status}` : ''}
        ${filter.filter.store ? `&store=${filter.filter.store}` : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await VoucherService.searchVoucherDetails(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      const status = {
        voucher_no: filter.voucher_no,
        filter: {
          partnerId: filter.filter.partnerId,
          reward: filter.filter.reward,
          status: filter.filter.status,
          startDate: filter.filter.startDate,
          endDate: filter.filter.endDate,
          dateBy: filter.filter.dateBy,
          store: filter.filter.store
        }
      }

      if (result.data.data.results.length > 0) {
        const currentVoucherDetails = result.data.data.results
        const pageStatus = {
          pageNumber: (result.data.data.currentPageable.pageNumber + 1),
          pageSize: result.data.data.currentPageable.pageSize,
          totalPages: result.data.data.totalPages,
          total: result.data.data.totalNumberOfElements
        }
        commit('setPageStatus', pageStatus)
        commit('setCurrentVoucherDetails', currentVoucherDetails)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setCurrentVoucherDetails', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
      commit('setFilterStatus', status)
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadCsv ({ commit, state }) {
    commit('processInProgress', null, { root: true })
    const search = (`${state.filter.voucher_no !== '' ? `&certificateId=${state.filter.voucher_no}` : ''}
        ${state.filter.filter.partnerId !== 0 ? `&partner=${state.filter.filter.partnerId}` : ''}
        ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
        ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
        ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
        ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
        ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}
        ${state.filter.filter.store ? `&store=${state.filter.filter.store}` : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await VoucherService.loadCsv(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.data.length > 0) {
        const data = result.data.data
        commit('setVoucherDetails', data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setVoucherDetails', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  }

}

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import moment from 'moment-timezone'
import { RootState, PageState, SortState } from '~/types/state'
import { InitVoucherState, Voucher, UpdateVoucherState, VoucherFilterState, VoucherInitSortState } from '~/types/vouchers'
import { VoucherService } from '~/services'

export const state = (): InitVoucherState => ({
  vouchers: [],
  currentVouchers: [],
  filter: {
    gkvr_no: 0,
    voucher_no: '',
    tag: '',
    filter: {
      partnerId: 0,
      reward: 0,
      status: '',
      dateBy: '',
      startDate: '',
      endDate: '',
      store: 0,
      promoCode: ''
    }
  },
  sort: {
    sortfield: VoucherInitSortState.SORT_FIELD,
    order: VoucherInitSortState.SORT_ORDER_DESC
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0,
    voucherQuantity: 0,
    voucherCost: 0
  },
  previouslySentVoucher: 0
})

export const getters: GetterTree<InitVoucherState, RootState> = {
  vouchers: state => ((state.vouchers).map((elem: Voucher) => {
    return ({
      id: elem.voucherNumber,
      certificateId: elem.certificateId,
      tag: elem.tag,
      reward: elem.reward.name,
      rewardCategory: elem.reward.category,
      points: elem.value.points ? elem.value.points : 0,
      cost: elem.value.cost ? elem.value.cost : 0,
      issuedDate: elem.issuedDate === null ? '' : moment(elem.issuedDate).tz('Jamaica', true).format('MMM-DD-YY'),
      expiryDate: elem.expiryDate === null ? '' : moment(elem.expiryDate).tz('Jamaica', true).format('MMM-DD-YY'),
      distributedDate: elem.distributedDate === null ? '' : moment(elem.distributedDate).tz('Jamaica', true).format('MMM-DD-YY'),
      dateExpired: elem.expiryDate,
      status: elem.status,
      memberName: `${elem.account.firstName ? elem.account.firstName : ''} ${elem.account.lastName ? elem.account.lastName : ''}`,
      accountNumber: elem.account.accountNumber,
      updatedOn: elem.modifiedDate === null ? '' : moment(elem.modifiedDate).format('MMM-DD-YY'),
      mobileNumber: elem.mobileNumber === null ? '' : elem.mobileNumber,
      quantity: elem.quantity === null ? '' : elem.quantity,
      emailAddress: elem.emailAddress === null ? '' : elem.emailAddress,
      store: elem.store ? elem.store.name : '',
      partner: elem.partner ? elem.partner.name : '',
      giftMeLink: elem.giftMeLink ? elem.giftMeLink : ''
    })
  })),
  currentVouchers: state => ((state.currentVouchers).map((elem: Voucher) => {
    return ({
      id: elem.voucherNumber,
      certificateId: elem.certificateId,
      tag: elem.tag,
      reward: elem.reward.name,
      rewardCategory: elem.reward.category,
      points: elem.value.points ? elem.value.points : 0,
      cost: elem.value.cost ? elem.value.cost : 0,
      issuedDate: elem.issuedDate === null ? '' : moment(elem.issuedDate).tz('Jamaica', true).format('MMM-DD-YY'),
      expiryDate: elem.expiryDate === null ? '' : moment(elem.expiryDate).tz('Jamaica', true).format('MMM-DD-YY'),
      distributedDate: elem.distributedDate === null ? '' : moment(elem.distributedDate).tz('Jamaica', true).format('MMM-DD-YY'),
      dateExpired: elem.expiryDate,
      status: elem.status,
      memberName: `${elem.account.firstName ? elem.account.firstName : ''} ${elem.account.lastName ? elem.account.lastName : ''}`,
      accountNumber: elem.account.accountNumber,
      updatedOn: elem.modifiedDate === null ? '' : moment(elem.modifiedDate).tz('Jamaica', true).format('MMM-DD-YY'),
      mobileNumber: elem.mobileNumber === null ? '' : elem.mobileNumber,
      quantity: elem.quantity === null ? '' : elem.quantity,
      emailAddress: elem.emailAddress === null ? '' : elem.emailAddress,
      partner: elem.partner ? elem.partner.name : '',
      partnerImage: elem.partner ? elem.partner.image : null,
      store: elem.store ? elem.store.name : '',
      promoCode: elem.promoCode,
      promoExpiryDate: elem.promoExpiryDate === null ? '' : moment(elem.promoExpiryDate).tz('Jamaica', true).format('MMM-DD-YY'),
      giftMeLink: elem.giftMeLink ? elem.giftMeLink : ''
    })
  })),
  pageStatus: (state) => {
    return state.pageStatus
  },
  filterState: (state) => {
    return state.filter
  }
}

export const mutations: MutationTree<InitVoucherState> = {
  setVouchers (state: InitVoucherState, vouchers: Array<Voucher>): void {
    state.vouchers = [...vouchers]
  },
  setCurrentVouchers (state: InitVoucherState, currentVouchers: Array<Voucher>): void {
    state.currentVouchers = [...currentVouchers]
  },
  setCurrentVoucherStatuses (state: InitVoucherState, currentVouchers: Array<Voucher>): void {
    const updatedVouchers = state.currentVouchers
    currentVouchers.forEach((v, i) => {
      updatedVouchers[i].status = v.status
    })
    state.currentVouchers = [...currentVouchers.length > 0 ? updatedVouchers : []]
  },
  reset (state: InitVoucherState): void {
    state.vouchers = []
    state.currentVouchers = []
    state.pageStatus.pageNumber = 1
    state.pageStatus.pageSize = 0
    state.pageStatus.totalPages = 0
    state.pageStatus.total = 0
    state.pageStatus.voucherCost = 0
    state.pageStatus.voucherQuantity = 0
    state.previouslySentVoucher = 0
  },
  setFilterStatus (state: InitVoucherState, status: VoucherFilterState): void {
    state.filter.gkvr_no = status.gkvr_no
    state.filter.voucher_no = status.voucher_no
    state.filter.tag = status.tag
    state.filter.filter.partnerId = status.filter.partnerId
    state.filter.filter.reward = status.filter.reward
    state.filter.filter.status = status.filter.status
    state.filter.filter.startDate = status.filter.startDate
    state.filter.filter.endDate = status.filter.endDate
    state.filter.filter.dateBy = status.filter.dateBy
    state.filter.filter.store = status.filter.store
    state.filter.filter.promoCode = status.filter.promoCode
  },
  setSortStatus (state: InitVoucherState, status: SortState): void {
    state.sort.sortfield = status.sortfield
    state.sort.order = status.order
  },
  setPageStatus (state: InitVoucherState, status: PageState): void {
    state.pageStatus.pageNumber = status.pageNumber
    state.pageStatus.pageSize = status.pageSize
    state.pageStatus.totalPages = status.totalPages
    state.pageStatus.total = status.total
    state.pageStatus.voucherCost = status.voucherCost
    state.pageStatus.voucherQuantity = status.voucherQuantity
  },
  setPreviouslySentVoucher (state: InitVoucherState, voucher: number): void {
    state.previouslySentVoucher = voucher
  }
}

export const actions: ActionTree<InitVoucherState, RootState> = {

  async updateVouchers ({ commit, state }, data: UpdateVoucherState) {
    if (!data.resultsAction) {
      commit('processInProgress', null, { root: true })
    }
    const currentVouchers = state.currentVouchers.slice()
    try {
      const result = await VoucherService.editVoucher(data.id, data.voucher)

      if (result.status === 200) {
        if (result.data) {
          const list = currentVouchers.map((elem: Voucher) => elem.voucherNumber === result.data.voucherNumber ? elem = result.data : elem)
          if (!data.resultsAction) {
            commit('setCurrentVouchers', list)
            commit('processSuccess', result.status, { root: true })
          }
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadVouchers ({ commit }) {
    commit('processInProgress', null, { root: true })

    const result = await VoucherService.getAllVouchers()
    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setVouchers', result.data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setVouchers', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadCurrentVouchers ({ commit, state }, data) {
    if (!data.resultsAction) {
      commit('processInProgress', null, { root: true })
    }

    try {
      const search = (`sort=${state.sort.sortfield},${state.sort.order}&page=${data.pageNumber ? (data.pageNumber - 1) : (state.pageStatus.pageNumber - 1)}
            ${state.filter.gkvr_no !== 0 ? `accountNumber=${state.filter.gkvr_no}` : ''}
            ${state.filter.voucher_no !== '' ? `&certificateId=${state.filter.voucher_no}` : ''}
            ${state.filter.filter.promoCode !== '' ? `&promoCode=${state.filter.filter.promoCode}` : ''}
            ${state.filter.tag !== '' ? `&tag=${state.filter.tag}` : ''}
            ${data.partnerId !== 0 ? `&partner=${data.partnerId}` : ''}
            ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
            ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
            ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
            ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
            ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}
            ${data.storeId ? `&store=${data.storeId}` : ''}`).trim().replace(/\s+(\&)/g, '&')

      const result = await VoucherService.searchVouchers(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.data.results.length > 0) {
          const currentVouchers = result.data.data.results
          const pageStatus: PageState = {
            pageNumber: (result.data.data.currentPageable.pageNumber + 1),
            pageSize: result.data.data.currentPageable.pageSize,
            totalPages: result.data.data.totalPages,
            total: result.data.data.totalNumberOfElements,
            voucherCost: result.data.summary.totalCost,
            voucherQuantity: result.data.summary.totalQuantity
          }
          commit('setPageStatus', pageStatus)
          commit('setCurrentVouchers', currentVouchers)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentVouchers', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async orderVouchers ({ commit, state }, data: SortState) {
    commit('processInProgress', null, { root: true })

    try {
      const search = (`sort=${data.sortfield},${data.order}&page=${(state.pageStatus.pageNumber - 1)}
            ${state.filter.gkvr_no !== 0 ? `accountNumber=${state.filter.gkvr_no}` : ''}
            ${state.filter.voucher_no !== '' ? `&certificateId=${state.filter.voucher_no}` : ''}
            ${state.filter.filter.promoCode !== '' ? `&promoCode=${state.filter.filter.promoCode}` : ''}
            ${state.filter.tag !== '' ? `&tag=${state.filter.tag}` : ''}
            ${data.partnerId && data.partnerId !== 0 ? `&partner=${data.partnerId}` : ''}
            ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
            ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
            ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
            ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
            ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}
            ${state.filter.filter.store ? `&store=${state.filter.filter.store}` : ''}`).trim().replace(/\s+(\&)/g, '&')

      const result = await VoucherService.searchVouchers(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.data.results.length > 0) {
          const currentVouchers = result.data.data.results
          const pageStatus: PageState = {
            pageNumber: (result.data.data.currentPageable.pageNumber + 1),
            pageSize: result.data.data.currentPageable.pageSize,
            totalPages: result.data.data.totalPages,
            total: result.data.data.totalNumberOfElements,
            voucherCost: result.data.summary.totalCost,
            voucherQuantity: result.data.summary.totalQuantity
          }
          const sortStatus = {
            sortfield: data.sortfield,
            order: data.order
          }
          commit('setSortStatus', sortStatus)
          commit('setPageStatus', pageStatus)
          commit('setCurrentVouchers', currentVouchers)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentVouchers', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async searchVouchers ({ commit, state }, filter: VoucherFilterState) {
    commit('processInProgress', null, { root: true })

    const search = (`sort=${state.sort.sortfield},${state.sort.order}
        ${filter.gkvr_no !== 0 ? `&accountNumber=${filter.gkvr_no}` : ''}
        ${filter.voucher_no !== '' ? `&certificateId=${filter.voucher_no}` : ''}
        ${filter.filter.promoCode !== '' ? `&promoCode=${filter.filter.promoCode}` : ''}
        ${filter.tag !== '' ? `&tag=${filter.tag}` : ''}
        ${filter.filter.partnerId !== 0 ? `&partner=${filter.filter.partnerId}` : ''}
        ${filter.filter.reward !== 0 ? `&reward=${filter.filter.reward}` : ''}
        ${filter.filter.startDate !== '' ? `&startDate=${filter.filter.startDate}` : ''}
        ${filter.filter.endDate !== '' ? `&endDate=${filter.filter.endDate}` : ''}
        ${filter.filter.dateBy !== '' ? `&dateBy=${filter.filter.dateBy}` : ''}
        ${filter.filter.status !== '' ? `&status=${filter.filter.status}` : ''}
        ${filter.filter.store ? `&store=${filter.filter.store}` : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await VoucherService.searchVouchers(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      const status = {
        gkvr_no: filter.gkvr_no,
        voucher_no: filter.voucher_no,
        tag: filter.tag,
        filter: {
          partnerId: filter.filter.partnerId,
          reward: filter.filter.reward,
          status: filter.filter.status,
          startDate: filter.filter.startDate,
          endDate: filter.filter.endDate,
          dateBy: filter.filter.dateBy,
          store: filter.filter.store,
          promoCode: filter.filter.promoCode
        }
      }

      if (result.data.data.results.length > 0) {
        const currentVouchers = result.data.data.results
        const pageStatus: PageState = {
          pageNumber: (result.data.data.currentPageable.pageNumber + 1),
          pageSize: result.data.data.currentPageable.pageSize,
          totalPages: result.data.data.totalPages,
          total: result.data.data.totalNumberOfElements,
          voucherCost: result.data.summary.totalCost,
          voucherQuantity: result.data.summary.totalQuantity
        }
        commit('setPageStatus', pageStatus)
        commit('setCurrentVouchers', currentVouchers)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setCurrentVouchers', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
      commit('setFilterStatus', status)
    } else {
      commit('processError', result, { root: true })
    }
  },

  async resendVouchers ({ commit, state }, data: UpdateVoucherState) {
    commit('processInProgress', null, { root: true })
    try {
      if (data.id !== state.previouslySentVoucher) {
        const result = await VoucherService.resendVoucher(data.id)
        if (result.status === 200) {
          commit('processSuccess', result.status, { root: true })
        } else {
          commit('processError', result, { root: true })
          throw new Error(result)
        }
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadCsv ({ commit, state }, data?: { useView: boolean }) {
    commit('processInProgressLong', null, { root: true })

    const search = (`${state.filter.gkvr_no !== 0 ? `accountNumber=${state.filter.gkvr_no}` : ''}
        ${state.filter.voucher_no !== '' ? `&certificateId=${state.filter.voucher_no}` : ''}
        ${state.filter.filter.promoCode !== '' ? `&promoCode=${state.filter.filter.promoCode}` : ''}
        ${state.filter.tag !== '' ? `&tag=${state.filter.tag}` : ''}
        ${state.filter.filter.partnerId !== 0 ? `&partner=${state.filter.filter.partnerId}` : ''}
        ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
        ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
        ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
        ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
        ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}
        ${state.filter.filter.store ? `&store=${state.filter.filter.store}` : ''}
        ${data?.useView ? '&useView=true' : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await VoucherService.loadCsv(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.data.length > 0) {
        const data = result.data.data
        commit('setVouchers', data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setVouchers', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  }
}

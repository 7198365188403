/* eslint-disable camelcase */
import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'

import { required, min, max, regex, email, confirmed, numeric, alpha_num, digits, required_if, ext } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('required_if', {
  ...required_if,
  message: 'This field is required'
})

extend('min', {
  ...min,
  message: 'This field has minimum length of {length}'
})

extend('max', {
  ...max,
  message: 'This field has maximum length of {length}'
})

extend('regex', {
  ...regex,
  message: 'Invalid input'
})

extend('email', {
  ...email,
  message: 'Invalid email'
})

extend('confirmed', {
  ...confirmed,
  message: 'Value does not match'
})

extend('numeric', {
  ...numeric,
  message: 'Value must be numeric'
})

extend('alpha_num', {
  ...alpha_num,
  message: 'Value must be alphanumeric'
})

extend('digits', {
  ...digits,
  message: 'Value must be digits'
})

extend('ext', {
  ...ext,
  message: 'File must have .jpg extension'
})

Vue.component(
  'ValidationObserver',
  ValidationObserver
)
Vue.component(
  'ValidationProvider',
  ValidationProvider
)

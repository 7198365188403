
import { Vue, Component } from 'vue-property-decorator'
import { EVENT_BUS } from '~/plugins/eventBus'
import AppSideNav from '~/components/side-nav/SideNav'
import AppUserProfileForm from '~/components/forms/UserProfileForm'
import Modal from '~/components/modal/Modal'
import AppFooter from '~/components/footer/footer.vue'
import { EventBusParams, StoreNames } from '~/types'

  @Component({
    components: {
      AppSideNav,
      AppUserProfileForm,
      Modal,
      AppFooter
    }
  })
export default class Default extends Vue {
  pageType: string = ''
  storePath: string = ''
  modalType: string = ''
  isModalVisible: boolean = false
  changePasswordEvent: string = 'changePassword'
  closeModalEvent:string = 'closeModal'

  get apiState () {
    return this.$store.getters.apiStatus
  }

  get isApiLoaderVisible ():boolean {
    const spinnerState = this.apiState.inProgress
    return spinnerState
  }

  get isLongApiLoaderVisible ():boolean {
    const spinnerState = this.apiState.inProgressLong
    return spinnerState
  }

  created () {
    if (process.browser) {
      this.$store.commit('setUserProfileState', JSON.parse(localStorage.getItem(StoreNames.LOCALSTORAGE_PROFILE) as string))
    }
  }

  mounted () {
    EVENT_BUS.$on(this.closeModalEvent, (params:boolean) => this.isModalVisible = params),
    EVENT_BUS.$on(this.changePasswordEvent, (params: EventBusParams) => {
      this.isModalVisible = params.state
      this.modalType = 'Update'
      this.pageType = 'Password'
    })
  }
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e63b9492 = () => interopDefault(import('../pages/approve-voucher/index.vue' /* webpackChunkName: "pages/approve-voucher/index" */))
const _37296ece = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4cbc026d = () => interopDefault(import('../pages/manage-notifications/index.vue' /* webpackChunkName: "pages/manage-notifications/index" */))
const _dd5a3426 = () => interopDefault(import('../pages/manage-promo-codes/index.vue' /* webpackChunkName: "pages/manage-promo-codes/index" */))
const _6fdeefe1 = () => interopDefault(import('../pages/manage-reward-category/index.vue' /* webpackChunkName: "pages/manage-reward-category/index" */))
const _7de22f9b = () => interopDefault(import('../pages/manage-reward-partner/index.vue' /* webpackChunkName: "pages/manage-reward-partner/index" */))
const _137efdae = () => interopDefault(import('../pages/manage-rewards/index.vue' /* webpackChunkName: "pages/manage-rewards/index" */))
const _5d5e8d26 = () => interopDefault(import('../pages/manage-store/index.vue' /* webpackChunkName: "pages/manage-store/index" */))
const _029b1d8d = () => interopDefault(import('../pages/manage-users/index.vue' /* webpackChunkName: "pages/manage-users/index" */))
const _3a4e1433 = () => interopDefault(import('../pages/manage-voucher/index.vue' /* webpackChunkName: "pages/manage-voucher/index" */))
const _0d6930f4 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _46dbb93e = () => interopDefault(import('../pages/voucher-history/index.vue' /* webpackChunkName: "pages/voucher-history/index" */))
const _04c16460 = () => interopDefault(import('../pages/voucher-summary/index.vue' /* webpackChunkName: "pages/voucher-summary/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/approve-voucher",
    component: _e63b9492,
    name: "approve-voucher"
  }, {
    path: "/forgot-password",
    component: _37296ece,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/manage-notifications",
    component: _4cbc026d,
    name: "manage-notifications"
  }, {
    path: "/manage-promo-codes",
    component: _dd5a3426,
    name: "manage-promo-codes"
  }, {
    path: "/manage-reward-category",
    component: _6fdeefe1,
    name: "manage-reward-category"
  }, {
    path: "/manage-reward-partner",
    component: _7de22f9b,
    name: "manage-reward-partner"
  }, {
    path: "/manage-rewards",
    component: _137efdae,
    name: "manage-rewards"
  }, {
    path: "/manage-store",
    component: _5d5e8d26,
    name: "manage-store"
  }, {
    path: "/manage-users",
    component: _029b1d8d,
    name: "manage-users"
  }, {
    path: "/manage-voucher",
    component: _3a4e1433,
    name: "manage-voucher"
  }, {
    path: "/reset-password",
    component: _0d6930f4,
    name: "reset-password"
  }, {
    path: "/voucher-history",
    component: _46dbb93e,
    name: "voucher-history"
  }, {
    path: "/voucher-summary",
    component: _04c16460,
    name: "voucher-summary"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

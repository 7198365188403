import { AxiosError } from 'axios'
import { BaseService } from './base.service'
import { axios } from '~/lib/axios'
import { PromoClaim } from '~/types'
import { APIResponse } from '~/types/api'

class PromoClaimService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------
  private readonly BASE_URL = '/api/v1/promo'
  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------
  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------
  public async getAllClaimsForCode (promoCode: string): Promise<APIResponse<PromoClaim[]>> {
    let response: APIResponse<PromoClaim[]>
    const endpoint = this.configs.API_HOST + this.BASE_URL

    try {
      const result = await axios.get(`${endpoint}/${promoCode}/claims`)
      response = { success: true, data: result.data }
    } catch (error: any) {
      response = { success: false, error: (error as AxiosError).response?.data }
    }
    return response
  }

  // TODO: check if this should be nested in the promo code service
  public async deleteClaim (promoCode: string): Promise<APIResponse<PromoClaim>> {
    let response: APIResponse<PromoClaim>
    const endpoint = this.configs.API_HOST + this.BASE_URL

    try {
      const result = await axios.delete(`${endpoint}/${promoCode}/claims`)
      response = { success: true, data: result.data }
    } catch (error: any) {
      response = { success: false, error: (error as AxiosError).response?.data }
    }
    return response
  }
}

export default PromoClaimService

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import moment from 'moment-timezone'
import { RootState, PageState, SortState } from '~/types/state'
import { InitRewardState, Reward, UpdateRewardState, CreateRewardState, RewardFilterState, InventoryState, RewardRedeemResponse, BlinkSkyReward, GiftMeCatalogResponse } from '~/types/rewards'
import { RewardService } from '~/services'

export const state = (): InitRewardState => ({
  rewards: [],
  currentRewards: [],
  statuses: [],
  filter: {
    term: '',
    filter: {
      status: 'All',
      partnerId: 0,
      category: 0,
      startDate: '',
      endDate: '',
      dateBy: ''
    }
  },
  sort: {
    sortfield: 'id',
    order: 'desc'
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0
  },
  rewardRedeemResponse: null,
  blinkSkyRewards: [],
  giftMeCatalog: []
})

export const getters: GetterTree < InitRewardState, RootState > = {
  rewards: state => ((state.rewards).filter(f => !f.isDeleted ? f : false).map((elem: Reward) => {
    return ({
      id: elem.id,
      name: elem.name,
      validityPeriodinDays: elem.validityPeriodinDays,
      cost: elem.value !== null ? elem.value.cost : null,
      points: elem.value !== null ? elem.value.points : null,
      value: elem.value !== null ? elem.value.value : null,
      valueId: elem.value !== null ? elem.value.id : null,
      fulfillmentType: elem.fulfillmentType,
      activeDate: elem.activeDate === null ? '' : moment(elem.activeDate).tz('Jamaica', true).format('MMM-DD-YY'),
      inactiveDate: elem.inactiveDate === null ? '' : moment(elem.inactiveDate).tz('Jamaica', true).format('MMM-DD-YY'),
      startDate: elem.activeDate,
      endDate: elem.inactiveDate,
      useByDate: elem.useByDate,
      published: elem.status,
      inventory: elem.inventory,
      useInventory: elem.useInventory ? InventoryState.USE : InventoryState.DONT_USE,
      partner: elem.partner.name,
      category: elem.category.name,
      description: elem.description,
      longTerms: elem.longTerms,
      shortTerms: elem.shortTerms,
      createdOn: elem.createdOn === null ? '' : moment(elem.createdOn).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.updatedOn === null ? '' : moment(elem.updatedOn).tz('Jamaica', true).format('MMM-DD-YY'),
      blinkSkyCode: elem.blinkSkyCode === null ? '' : elem.blinkSkyCode,
      blinkSkyValue: elem.blinkSkyValue === null ? '' : elem.blinkSkyValue,
      image: elem.image,
      status: elem.status,
      giftMeCode: elem.giftMeCode,
      giftMeValue: elem.giftMeValue
    })
  })),
  rewardsDropDown: state => ((state.rewards).filter(f => !f.isDeleted ? f : false).map((elem: Reward) => {
    return ({
      id: elem.id,
      name: elem.name
    })
  })).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1),
  currentRewards: state => ((state.currentRewards).filter(f => !f.isDeleted ? f : false).map((elem: Reward) => {
    return ({
      id: elem.id,
      name: elem.name,
      validityPeriodinDays: elem.validityPeriodinDays,
      cost: elem.value.cost,
      points: elem.value.points,
      value: elem.value.value,
      valueId: elem.value.id,
      fulfillmentType: elem.fulfillmentType,
      activeDate: elem.activeDate === null ? '' : moment(elem.activeDate).tz('Jamaica', true).format('MMM-DD-YY'),
      inactiveDate: elem.inactiveDate === null ? '' : moment(elem.inactiveDate).tz('Jamaica', true).format('MMM-DD-YY'),
      startDate: elem.activeDate,
      endDate: elem.inactiveDate,
      useByDate: elem.useByDate,
      published: elem.status,
      inventory: elem.inventory,
      useInventory: elem.useInventory ? InventoryState.USE : InventoryState.DONT_USE,
      partner: elem.partner.name,
      category: elem.category.name,
      description: elem.description,
      longTerms: elem.longTerms,
      shortTerms: elem.shortTerms,
      createdOn: elem.createdOn === null ? '' : moment(elem.createdOn).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.updatedOn === null ? '' : moment(elem.updatedOn).tz('Jamaica', true).format('MMM-DD-YY'),
      blinkSkyCode: elem.blinkSkyCode === null ? '' : elem.blinkSkyCode,
      blinkSkyValue: elem.blinkSkyValue === null ? '' : elem.blinkSkyValue,
      status: elem.status,
      giftMeCode: elem.giftMeCode,
      giftMeValue: elem.giftMeValue
    })
  })),
  pageStatus: (state) => {
    return state.pageStatus
  },

  rewardRedeemResponse: (state) => {
    return state.rewardRedeemResponse
  },

  blinkSkyRewards: (state) => {
    return state.blinkSkyRewards
  },
  statuses: state => state.statuses,
  giftMeCatalog: state => state.giftMeCatalog
}

export const mutations: MutationTree < InitRewardState > = {
  setRewards (state: InitRewardState, rewards: Array < Reward >): void {
    state.rewards = [...rewards]
  },
  setCurrentRewards (state: InitRewardState, currentRewards: Array < Reward >): void {
    state.currentRewards = [...currentRewards]
  },
  reset (state: InitRewardState): void {
    state.rewards = []
    state.currentRewards = []
    state.pageStatus.pageNumber = 1
    state.pageStatus.pageSize = 0
    state.pageStatus.totalPages = 0
    state.pageStatus.total = 0
  },
  setFilterStatus (state: InitRewardState, status: RewardFilterState): void {
    state.filter.term = status.term
    state.filter.filter.status = status.filter.status
    state.filter.filter.partnerId = status.filter.partnerId
    state.filter.filter.category = status.filter.category
    state.filter.filter.startDate = status.filter.startDate
    state.filter.filter.endDate = status.filter.endDate
    state.filter.filter.dateBy = status.filter.dateBy
  },
  setSortStatus (state: InitRewardState, status: SortState): void {
    state.sort.sortfield = status.sortfield
    state.sort.order = status.order
  },
  setPageStatus (state: InitRewardState, status: PageState): void {
    state.pageStatus.pageNumber = status.pageNumber
    state.pageStatus.pageSize = status.pageSize
    state.pageStatus.totalPages = status.totalPages
    state.pageStatus.total = status.total
  },
  setAdminRedeemResponse (state: InitRewardState, rewardRedeemResponse : RewardRedeemResponse): void {
    state.rewardRedeemResponse = rewardRedeemResponse
  },
  setBlinkSkyRewards (state: InitRewardState, blinkSkyCatalogResponse: Array< BlinkSkyReward >): void {
    state.blinkSkyRewards = [...blinkSkyCatalogResponse]
  },
  setStatuses (state: InitRewardState, statuses: string[]) {
    state.statuses = [...statuses]
  },
  setGiftMeCatalog (state: InitRewardState, GiftMeCatalogResponse: Array<GiftMeCatalogResponse>): void {
    state.giftMeCatalog = [...GiftMeCatalogResponse].sort((a, b) => {
      let result = 0
      if (a.name > b.name) {
        result = 1
      } else if (b.name > a.name) {
        result = -1
      }
      return result
    })
  }
}

export const actions: ActionTree < InitRewardState, RootState > = {

  async createRewards ({ commit, dispatch, state }, createRequest: CreateRewardState) {
    commit('processInProgress', null, { root: true })
    try {
      const reward = await RewardService.createReward(createRequest.reward)
      if (reward.status === 200) {
        const result = await RewardService.createRewardValue(reward.data.id, createRequest.value)
        if (result.status === 200) {
          await dispatch('loadCurrentRewards', { pageStatus: state.pageStatus, sort: state.sort })
          await commit('processSuccess', result.status, { root: true })
        } else {
          commit('processError', result, { root: true })
          throw new Error(result)
        }
      } else {
        commit('processError', reward, { root: true })
        throw new Error(reward)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async updateRewards ({ commit, dispatch, state }, data: UpdateRewardState) {
    commit('processInProgress', null, { root: true })
    try {
      const reward = await RewardService.editReward(data.id, data.reward)

      if (reward && reward.status === 200) {
        if (reward.data !== undefined) {
          let result
          if (reward.data.value) {
            result = await RewardService.editRewardValue(reward.data.id, reward.data.value.id, data.value)
          } else {
            result = await RewardService.createRewardValue(reward.data.id, data.value)
          }
          if (result && result.status === 200) {
            await dispatch('loadCurrentRewards', {
              pageStatus: state.pageStatus,
              sort: state.sort
            })
            await commit('processSuccess', result.status, { root: true })
          } else {
            commit('processError', result, { root: true })
            throw new Error(result)
          }
        }
      } else {
        commit('processError', reward, { root: true })
        throw new Error(reward)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async deleteRewards ({ commit, state }, data) {
    commit('processInProgress', null, { root: true })

    const currentRewards = state.currentRewards.slice()
    try {
      const result = await RewardService.deleteReward(data.id)
      if (result.status === 200) {
        const list = currentRewards.filter((elem: Reward) => elem.id === data.id ? false : elem)
        commit('processSuccess', result.status, { root: true })
        commit('setCurrentRewards', list)
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadRewards ({ commit }) {
    commit('processInProgress', null, { root: true })

    const result = await await RewardService.getAllRewards()
    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setRewards', result.data)
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setRewards', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadCurrentRewards ({ commit, state }, data) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${state.sort.sortfield},${state.sort.order}
            &page=${data.pageNumber ? (data.pageNumber - 1) : (state.pageStatus.pageNumber - 1)}
            ${data.partnerId && data.partnerId !== 0 ? `&partner=${data.partnerId}` : ''}
            ${state.filter.filter.category !== 0 ? `&categories=${state.filter.filter.category}` : ''}
            ${`&status=${state.filter.filter.status}`}
            ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
            ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
            ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}`).trim().replace(/\s+(\&)/g, '&')

      const result = await RewardService.searchRewards(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentRewards = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          commit('setPageStatus', pageStatus)
          commit('setCurrentRewards', currentRewards)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentRewards', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadRewardsByPartner ({ commit }, id) {
    const result = await RewardService.getRewardsByPartner(id)

    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setRewards', result.data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setRewards', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadRewardStatuses ({ commit }) {
    const result = await RewardService.rewardStatuses()

    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setStatuses', result.data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setStatuses', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async orderRewards ({ commit, state }, data: SortState) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${data.sortfield},${data.order}
            &page=${(state.pageStatus.pageNumber - 1)}
            ${data.partnerId && data.partnerId !== 0 ? `&partner=${data.partnerId}` : ''}
            ${state.filter.filter.category !== 0 ? `&categories=${state.filter.filter.category}` : ''}
            ${`&status=${state.filter.filter.status}`}
            ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
            ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}`).trim().replace(/\s+(\&)/g, '&')

      const result = await RewardService.searchRewards(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentRewards = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          const sortStatus = {
            sortfield: data.sortfield,
            order: data.order
          }
          commit('setSortStatus', sortStatus)
          commit('setPageStatus', pageStatus)
          commit('setCurrentRewards', currentRewards)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentRewards', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async searchRewards ({ commit }, filter: RewardFilterState) {
    commit('processInProgress', null, { root: true })

    const search = (`${filter.term === '' || filter.term === undefined ? '' : `query=${filter.term}`}
        ${filter.filter.partnerId && filter.filter.partnerId !== 0 ? `&partner=${filter.filter.partnerId}` : ''}
        ${`&status=${filter.filter.status}`}
        ${filter.filter.category !== 0 ? `&categories=${filter.filter.category}` : ''}
        ${filter.filter.startDate !== '' ? `&startDate=${filter.filter.startDate}` : ''}
        ${filter.filter.dateBy !== '' ? `&dateBy=${filter.filter.dateBy}` : ''}
        ${filter.filter.endDate !== '' ? `&endDate=${filter.filter.endDate}` : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await RewardService.searchRewards(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.results.length > 0) {
        const currentRewards = result.data.results
        const status = {
          term: filter.term,
          filter: {
            status: filter.filter.status,
            partnerId: filter.filter.partnerId,
            category: filter.filter.category,
            startDate: filter.filter.startDate,
            endDate: filter.filter.endDate,
            dateBy: filter.filter.dateBy
          }
        }
        const pageStatus = {
          pageNumber: (result.data.currentPageable.pageNumber + 1),
          pageSize: result.data.currentPageable.pageSize,
          totalPages: result.data.totalPages,
          total: result.data.totalNumberOfElements
        }
        commit('setPageStatus', pageStatus)
        commit('setFilterStatus', status)
        commit('setCurrentRewards', currentRewards)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setCurrentRewards', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  },

  async loadCsv ({ commit, state }) {
    commit('processInProgress', null, { root: true })

    const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
        ${state.filter.filter.partnerId && state.filter.filter.partnerId !== 0 ? `&partner=${state.filter.filter.partnerId}` : ''}
        ${state.filter.filter.category !== 0 ? `&categories=${state.filter.filter.category}` : ''}
        ${`&status=${state.filter.filter.status}`}
        ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
        ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
        ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await RewardService.loadCsv(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.length > 0) {
        const data = result.data
        await commit('setRewards', data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setRewards', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadBlinkSkyRewards ({ commit }) {
    commit('processInProgress', null, { root: true })

    const result = await RewardService.getBlinkSkyCatalog()

    const { status, data } = result

    if (status === 200) {
      if (data.d.length > 0) {
        const modifiedData = data.d
        data.d.forEach((r, i) => {
          // Some captions are present with extra spaces and need to be sanitized
          const captionSanitized = r.caption.split(' ').filter(w => w.length > 0).join(' ')
          modifiedData[i].caption = captionSanitized

          // Set voucher values
          // If min and max is the same voucher has one value
          if (r.min_range === r.max_range) {
            modifiedData[i].values = [r.value]
          } else {
            modifiedData[i].values = r.value.split(',')
          }
        })

        commit('setBlinkSkyRewards', modifiedData)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', 128, { root: true })
    }
  },

  async loadGiftMeCatalog ({ commit }) {
    commit('processInProgress', null, { root: true })

    const result = await RewardService.getGiftMeCatalog()

    const { status, data } = result

    if (status === 200) {
      if (data.length > 0) {
        const modifiedData = data
        data.forEach((r, i) => {
          // Set voucher values
          // If min and max is the same voucher has one value
          if (r.min_amount === r.max_amount) {
            modifiedData[i].values = [r.min_amount.toString()]
          } else {
            modifiedData[i].values = (r.value).split(',')
          }
        })

        commit('setGiftMeCatalog', data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', 128, { root: true })
    }
  }
}

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState, AccountState, Account, UserProfile, UpdatePassword, StoreNames } from "~/types";
import { TokenService } from '~/services/token.service';
import { setupServices, AccountService } from '~/services';

export const state = (): AccountState => ({
  auth: false,
  account: {
    authorities: []
  },
  accountProfile: {
    id: 0,
    firstName: '',
    lastName: '',
    emailAddress: '',
    partner: {
      id: 0,
      name: ''
    },
    role: {
      id: 0,
      name: ''
    }
  },
  apiPocessState: {
    inProgressLong: false,
    inProgress: false,
    completed: false,
    success: false,
    error: false,
    statusCode: 0
  },
  env: {}
})


export const getters: GetterTree<AccountState, RootState> = {
  authorities: state => state.account.authorities,
  apiStatus: state => state.apiPocessState,
  env: (state: any) => state.env,
  profile: state => state.accountProfile,
}

export const mutations: MutationTree<AccountState> = {
  setEnv: (state: any, env: any) => state.env = env,
  setAuthorities(state: AccountState, account: Account): void {
    state.account.authorities = account.authorities;
  },
  setAccountProfile(state: AccountState, profile: UserProfile): void {
    localStorage.setItem(StoreNames.LOCALSTORAGE_PROFILE, JSON.stringify(profile));
    state.accountProfile.id = profile.id;
    state.accountProfile.firstName = profile.firstName;
    state.accountProfile.lastName = profile.lastName;
    state.accountProfile.emailAddress = profile.emailAddress;
    state.accountProfile.role.id = profile.role.id;
    state.accountProfile.role.name = profile.role.name;
    if (state.accountProfile.partner) {
      state.accountProfile.partner.id = profile.partner ? profile.partner.id : 0;
      state.accountProfile.partner.name = profile.partner ? profile.partner.name : '';
    }
  },
  setUserProfileState(state: AccountState, profile: UserProfile): void {
    state.accountProfile = profile;
  },
  setAuthenticated(state: AccountState, isAuthenticated: boolean): void {
    state.auth = isAuthenticated;
  },
  reset(state: AccountState): void {
    state.auth = false;
    state.accountProfile.id = 0;
    state.accountProfile.firstName = '';
    state.accountProfile.lastName = '';
    state.accountProfile.emailAddress = '';
    state.accountProfile.role.id = 0;
    state.accountProfile.role.name = '';
    if (state.accountProfile.partner) {
      state.accountProfile.partner.id = 0;
      state.accountProfile.partner.name = '';
    }
    state.account.authorities = [];

    state.apiPocessState.inProgressLong = false;
    state.apiPocessState.inProgress = false;
    state.apiPocessState.completed = false;
    state.apiPocessState.success = false;
    state.apiPocessState.error = false
    state.apiPocessState.statusCode = 0;
  },
  resetAPIProcessState(state: AccountState): void {
    state.apiPocessState.inProgressLong = false;
    state.apiPocessState.inProgress = false;
    state.apiPocessState.completed = false;
    state.apiPocessState.success = false;
    state.apiPocessState.error = false;
    state.apiPocessState.statusCode = 1;
  },
  processInProgress(state: AccountState): void {
    state.apiPocessState.inProgress = true;
    state.apiPocessState.completed = false;
    state.apiPocessState.success = false;;
  },
  processInProgressLong(state: AccountState): void {
    state.apiPocessState.inProgressLong = true;
    state.apiPocessState.inProgress = true;
    state.apiPocessState.completed = false;
    state.apiPocessState.success = false;
  },
  processSuccess(state: AccountState, code): void {
    state.apiPocessState.inProgressLong = false;
    state.apiPocessState.inProgress = false;
    state.apiPocessState.completed = true;
    state.apiPocessState.success = true;
    state.apiPocessState.error = false;
    state.apiPocessState.statusCode = Number(code);
  },
  processError(state: AccountState, error: any): void {
    state.apiPocessState.inProgressLong = false;
    state.apiPocessState.inProgress = false
    state.apiPocessState.completed = true
    state.apiPocessState.success = false
    state.apiPocessState.error = true
    state.apiPocessState.statusCode = Number(error);
  }

}

const cookieparser = process.server ? require('cookieparser') : undefined;

export const actions: ActionTree<AccountState, RootState> = {
  setupEnvironmentVariables({ commit, state }, host: string) {
    if (process.server) {
      commit('setEnv', {
        API_HOST: process.env.API_HOST,
        CLIENT_PORTAL_URL: process.env.CLIENT_PORTAL_URL,
        NUXT_API_PATH: process.env.NUXT_API_PATH,
        NUXT_SERVER_HOST: host
      });
    }

    setupServices(state.env);
  },
  async nuxtServerInit({ commit }, { req }) {

    let isAuthenticated = false;
    let auth_user_data: Account;

    // Do we have cookies setup
    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie);

      try {
        // check for access token in cookie
        isAuthenticated = (parsed.access_token && parsed.refresh_token) ? ((parsed.access_token !== 'undefined' && parsed.refresh_token !== 'undefined') ? true : false) : false;

        // decodes user account data from access token
        const decoded = TokenService.decodeJwt(parsed.access_token) as unknown as Record<string, string[]>;

        auth_user_data = {
          authorities: decoded.authorities
        }
        commit("setAuthorities", auth_user_data);

      } catch (err) {
        // No valid cookie found
      }
    }
    commit("setAuthenticated", isAuthenticated);
  },
  async loadAccountInfo({ commit }) {
    let result = await AccountService.loadAccountInfo();

    if (result.status === 200) {
      if (result.data) {
        commit('setAccountProfile', result.data);
      } else {
        commit('setAccountProfile',
          {
            id: 0,
            firstName: '',
            lastName: '',
            emailAddress: '',
            partner: {
              id: 0,
              name: ''
            },
            role: {
              id: 0,
              name: ''
            }
          });
      }
    } else {
      commit('setAccountProfile',
        {
          id: 0,
          firstName: '',
          lastName: '',
          emailAddress: '',
          partner: {
            id: 0,
            name: ''
          },
          role: {
            id: 0,
            name: ''
          }
        });
      commit('processError', result);
    }
  },
  async updateAccountPassword({ commit }, data: UpdatePassword) {
    commit('processInProgress');

    try {
      let result = await AccountService.updateAccountpassword(data);

      if (result.status === 200) {
        if (result.data !== undefined) {
          commit('processSuccess', result.status);
        }
      } else {
        commit('processError', result);
        throw new Error(result);
      }
    } catch (error) {
      console.log(error);
    }
  }

}
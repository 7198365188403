import { Component, Vue } from 'vue-property-decorator'
import { TokenService } from '../../services/token.service'
import { EVENT_BUS } from '~/plugins/eventBus'
import { Authorities, Routes, StoreNames, UserProfile } from '~/types'
import { AccountService } from '~/services'
import { AFFILIATED_LINKS } from '~/core/constants'

@Component({
  name: 'app-side-nav'
})
export default class AppSideNav extends Vue {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------
  AUTHORITIES = Authorities
  ROUTES = Routes
  authoritiesState: Array<string> = []

  isSideNavOpen:boolean = false
  isProfileDropDownOpen:boolean = false
  sideNavStyle:string = ''

  changePasswordEvent:string = 'changePassword'
  ETL_LINK = ''
  SPOONITY_LINK = ''
  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Computed Methods
  // --------------------------------------------------------------------------
  get userProfile ():UserProfile {
    const profile: UserProfile = this.$store.getters.profile
    return profile
  }

  get pageName () {
    const pageRoute = this.$nuxt.$route.path !== Routes.LOGIN ? (this.$nuxt.$route.path).substring(1).split('-') : false

    const pageName = pageRoute
      ? `${(pageRoute[0]).charAt(0).toUpperCase() + (pageRoute[0]).substring(1)} 
    ${(pageRoute[1]).charAt(0).toUpperCase() + (pageRoute[1]).substring(1)} 
    ${(pageRoute[2]) ? (pageRoute[2]).charAt(0).toUpperCase() + (pageRoute[2]).substring(1) : ''}`
      : ''

    return pageName
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  openProfileDropDown () {
    this.isProfileDropDownOpen = !this.isProfileDropDownOpen
  }

  closeProfileDropDown () {
    this.isProfileDropDownOpen = false
  }

  changePassword () {
    this.isProfileDropDownOpen = false
    EVENT_BUS.$emit(this.changePasswordEvent, { state: true, data: null })
  }

  currentPage () {
    return this.$nuxt.$route.path
  }

  async logout () {
    TokenService.removeCookies()
    localStorage.removeItem(StoreNames.LOCALSTORAGE_PROFILE)

    const creds = await AccountService.authenticateRequest()
    TokenService.saveCredentials(creds.accessToken, '')

    this.$store.commit(`${StoreNames.STORE_PARTNER}/reset`)
    this.$store.commit(`${StoreNames.STORE_USERS}/reset`)
    this.$store.commit(`${StoreNames.STORE_REWARDS}/reset`)
    this.$store.commit(`${StoreNames.STORE_CATEGORY}/reset`)
    this.$store.commit(`${StoreNames.STORE_VOUCHER}/reset`)
    this.$store.commit(`${StoreNames.STORE_VOUCHER_DETAILS}/reset`)
    this.$store.commit(`${StoreNames.STORE_VOUCHER_SUMMARY}/reset`)
    this.$store.commit('reset')

    this.$router.push({ path: this.ROUTES.LOGIN })
  }

  closeSideNav () {
    this.isSideNavOpen = false
  }

  openSideNav () {
    this.isSideNavOpen = true
  }
  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Lifecycle Hooks
  // --------------------------------------------------------------------------
  mounted () {
    this.authoritiesState = this.$store.getters.authorities

    const { API_HOST } = this.$store.getters.env

    if (API_HOST) {
      if (API_HOST.includes('stage') || API_HOST.includes('local')) {
        this.ETL_LINK = AFFILIATED_LINKS.STAGE.etl
        this.SPOONITY_LINK = AFFILIATED_LINKS.STAGE.spoonity
      } else {
        this.ETL_LINK = AFFILIATED_LINKS.PROD.etl
        this.SPOONITY_LINK = AFFILIATED_LINKS.PROD.spoonity
      }
    }
  }
}

import { Component, Vue, Prop } from 'vue-property-decorator'
import { EVENT_BUS } from '~/plugins/eventBus'

@Component({
  name: 'app-modal'
})
export default class AppModal extends Vue {
  @Prop() modalTitle!: string
  @Prop() modalType!: string
  @Prop() closeModalEvent!: string
  @Prop() storePath!: string
  @Prop() isRedemption!: boolean

  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Computed Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------
  closeModal () {
    const params = false
    this.$store.commit('resetAPIProcessState', null, { root: true })
    EVENT_BUS.$emit(this.closeModalEvent, params)
    this.$emit(this.closeModalEvent, params)
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Lifecycle Hooks
  // --------------------------------------------------------------------------
}

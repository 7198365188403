const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.ts')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['nuxt/api/v1/index'] = require('../middleware/nuxt/api/v1/index.js')
middleware['nuxt/api/v1/index'] = middleware['nuxt/api/v1/index'].default || middleware['nuxt/api/v1/index']

export default middleware

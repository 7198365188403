export const ActionButtonsBulkActions = () => import('../../components/action-buttons/BulkActions.ts' /* webpackChunkName: "components/action-buttons-bulk-actions" */).then(c => wrapFunctional(c.default || c))
export const ActionButtonsDownloadCsv = () => import('../../components/action-buttons/DownloadCsv.ts' /* webpackChunkName: "components/action-buttons-download-csv" */).then(c => wrapFunctional(c.default || c))
export const ActionButtonsSingleAction = () => import('../../components/action-buttons/SingleAction.ts' /* webpackChunkName: "components/action-buttons-single-action" */).then(c => wrapFunctional(c.default || c))
export const DataTable = () => import('../../components/data-table/DataTable.ts' /* webpackChunkName: "components/data-table" */).then(c => wrapFunctional(c.default || c))
export const DisplayApiResultCard = () => import('../../components/display-cards/DisplayApiResultCard.ts' /* webpackChunkName: "components/display-api-result-card" */).then(c => wrapFunctional(c.default || c))
export const DisplayResultsCard = () => import('../../components/display-cards/DisplayResultsCard.ts' /* webpackChunkName: "components/display-results-card" */).then(c => wrapFunctional(c.default || c))
export const DisplayVoucherResultsCard = () => import('../../components/display-cards/DisplayVoucherResultsCard.ts' /* webpackChunkName: "components/display-voucher-results-card" */).then(c => wrapFunctional(c.default || c))
export const DisplayCardsRewardBulkRedemptionCard = () => import('../../components/display-cards/RewardBulkRedemptionCard.ts' /* webpackChunkName: "components/display-cards-reward-bulk-redemption-card" */).then(c => wrapFunctional(c.default || c))
export const DisplayCardsRewardRedemptionCard = () => import('../../components/display-cards/RewardRedemptionCard.ts' /* webpackChunkName: "components/display-cards-reward-redemption-card" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormsPartnerForm = () => import('../../components/forms/PartnerForm.ts' /* webpackChunkName: "components/forms-partner-form" */).then(c => wrapFunctional(c.default || c))
export const FormsPromoCodeForm = () => import('../../components/forms/PromoCodeForm.ts' /* webpackChunkName: "components/forms-promo-code-form" */).then(c => wrapFunctional(c.default || c))
export const FormsRewardCategoryForm = () => import('../../components/forms/RewardCategoryForm.ts' /* webpackChunkName: "components/forms-reward-category-form" */).then(c => wrapFunctional(c.default || c))
export const FormsRewardForm = () => import('../../components/forms/RewardForm.ts' /* webpackChunkName: "components/forms-reward-form" */).then(c => wrapFunctional(c.default || c))
export const FormsStoreForm = () => import('../../components/forms/StoreForm.ts' /* webpackChunkName: "components/forms-store-form" */).then(c => wrapFunctional(c.default || c))
export const FormsUserForm = () => import('../../components/forms/UserForm.ts' /* webpackChunkName: "components/forms-user-form" */).then(c => wrapFunctional(c.default || c))
export const FormsUserProfileForm = () => import('../../components/forms/UserProfileForm.ts' /* webpackChunkName: "components/forms-user-profile-form" */).then(c => wrapFunctional(c.default || c))
export const FormsVoucherForm = () => import('../../components/forms/VoucherForm.ts' /* webpackChunkName: "components/forms-voucher-form" */).then(c => wrapFunctional(c.default || c))
export const GkvrButton = () => import('../../components/gkvr-button/GkvrButton.ts' /* webpackChunkName: "components/gkvr-button" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/modal/Modal.ts' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination/Pagination.ts' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PromoClaimTable = () => import('../../components/promo-claim-table/PromoClaimTable.ts' /* webpackChunkName: "components/promo-claim-table" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersPartnersSearchFilter = () => import('../../components/search-filters/PartnersSearchFilter.ts' /* webpackChunkName: "components/search-filters-partners-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersPromoCodeSearchFilter = () => import('../../components/search-filters/PromoCodeSearchFilter.ts' /* webpackChunkName: "components/search-filters-promo-code-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersRewardCategorySearchFilter = () => import('../../components/search-filters/RewardCategorySearchFilter.ts' /* webpackChunkName: "components/search-filters-reward-category-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersRewardSearchFilter = () => import('../../components/search-filters/RewardSearchFilter.ts' /* webpackChunkName: "components/search-filters-reward-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersStoresSearchFilter = () => import('../../components/search-filters/StoresSearchFilter.ts' /* webpackChunkName: "components/search-filters-stores-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersUsersSearchFilter = () => import('../../components/search-filters/UsersSearchFilter.ts' /* webpackChunkName: "components/search-filters-users-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersVoucherDetailsSearchFilter = () => import('../../components/search-filters/VoucherDetailsSearchFilter.ts' /* webpackChunkName: "components/search-filters-voucher-details-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersVoucherLookUp = () => import('../../components/search-filters/VoucherLookUp.ts' /* webpackChunkName: "components/search-filters-voucher-look-up" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersVoucherSearchFilter = () => import('../../components/search-filters/VoucherSearchFilter.ts' /* webpackChunkName: "components/search-filters-voucher-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFiltersVoucherSummarySearchFilter = () => import('../../components/search-filters/VoucherSummarySearchFilter.ts' /* webpackChunkName: "components/search-filters-voucher-summary-search-filter" */).then(c => wrapFunctional(c.default || c))
export const SideNav = () => import('../../components/side-nav/SideNav.ts' /* webpackChunkName: "components/side-nav" */).then(c => wrapFunctional(c.default || c))
export const VoucherCard = () => import('../../components/voucher-card/voucher-card.ts' /* webpackChunkName: "components/voucher-card" */).then(c => wrapFunctional(c.default || c))
export const VouchersVoucherDisplay = () => import('../../components/vouchers/voucherDisplay.ts' /* webpackChunkName: "components/vouchers-voucher-display" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState, PageState, SortState } from '~/types/state'
import { InitUserState, CreatedUser, UpdateUserState, UsersFilterState, CustomerLookup } from '~/types/users'
import { UserService } from '~/services'

export const state = (): InitUserState => ({
  users: [],
  currentUsers: [],
  customer: null,
  filter: {
    term: '',
    filter: {
      roleId: 0,
      partnerId: 0
    }
  },
  sort: {
    sortfield: 'firstName',
    order: 'desc'
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0
  }
})

export const getters: GetterTree < InitUserState, RootState > = {
  users: state => ((state.users).filter(f => !f.isDeleted ? f : false).map((elem: CreatedUser) => {
    return ({
      id: elem.id,
      firstName: elem.firstName,
      lastName: elem.lastName,
      emailAddress: elem.emailAddress,
      phoneNumber: elem.phoneNumber,
      roleId: elem.role ? elem.role.id : null,
      role: elem.role ? elem.role.name : null,
      partnerId: elem.partner ? elem.partner.id : null
    })
  })),
  currentUsers: state => ((state.currentUsers).filter(f => !f.isDeleted ? f : false).map((elem: CreatedUser) => {
    return ({
      id: elem.id,
      firstName: elem.firstName,
      lastName: elem.lastName,
      emailAddress: elem.emailAddress,
      phoneNumber: elem.phoneNumber,
      roleId: elem.role ? elem.role.id : null,
      role: elem.role ? elem.role.name : null,
      partnerId: elem.partner ? elem.partner.id : null
    })
  })),
  customer: (state) => {
    return state.customer as CustomerLookup
  },
  pageStatus: (state) => {
    return state.pageStatus
  }
}

export const mutations: MutationTree < InitUserState > = {
  setUsers (state: InitUserState, users: Array < CreatedUser >): void {
    state.users = [...users]
  },
  setCustomer (state: InitUserState, customer: CustomerLookup): void {
    state.customer = customer
  },
  setCurrentUsers (state: InitUserState, currentUsers: Array < CreatedUser >): void {
    state.currentUsers = [...currentUsers]
  },
  reset (state: InitUserState): void {
    state.users = []
    state.currentUsers = []
    state.pageStatus.pageNumber = 1
    state.pageStatus.pageSize = 0
    state.pageStatus.totalPages = 0
    state.pageStatus.total = 0
  },
  setFilterStatus (state: InitUserState, status: UsersFilterState): void {
    state.filter.term = status.term
    state.filter.filter.partnerId = status.filter.partnerId
    state.filter.filter.roleId = status.filter.roleId
  },
  setSortStatus (state: InitUserState, status: SortState): void {
    state.sort.sortfield = status.sortfield
    state.sort.order = status.order
  },
  setPageStatus (state: InitUserState, status: PageState): void {
    state.pageStatus.pageNumber = status.pageNumber
    state.pageStatus.pageSize = status.pageSize
    state.pageStatus.totalPages = status.totalPages
    state.pageStatus.total = status.total
  }
}

export const actions: ActionTree < InitUserState, RootState > = {

  async createUsers ({ commit, dispatch, state }, createUserRequest: CreatedUser) {
    commit('processInProgress', null, { root: true })
    try {
      const result = await UserService.createUser(createUserRequest)
      if (result.status === 200) {
        await dispatch('loadCurrentUsers', { pageStatus: state.pageStatus, sort: state.sort })
        commit('processSuccess', result.status, { root: true })
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async updateUsers ({ commit, state }, data: UpdateUserState) {
    commit('processInProgress', null, { root: true })
    const currentUsers = state.currentUsers.slice()
    try {
      const result = await UserService.editUser(data.id, data.user)

      if (result.status === 200) {
        if (result.data !== undefined) {
          const list = currentUsers.map((elem:CreatedUser) => elem.id === result.data.id ? elem = result.data : elem)
          commit('setCurrentUsers', list)
          commit('processSuccess', result.status, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async deleteUsers ({ commit, state }, data: any) {
    commit('processInProgress', null, { root: true })
    const currentUsers = state.currentUsers.slice()
    try {
      const result = await UserService.deleteUser(data.id)
      if (result.status === 200) {
        const list = currentUsers.filter((elem:CreatedUser) => elem.id === data.id ? false : elem)
        commit('setCurrentUsers', list)
        commit('processSuccess', result.status, { root: true })
      } else {
        await commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadUsers ({ commit }) {
    commit('processInProgress', null, { root: true })
    const result = await UserService.getAllUsers()

    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setUsers', result.data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setUsers', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadCurrentUsers ({ commit, state }, pageNumber) {
    commit('processInProgress', null, { root: true })

    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${state.sort.sortfield},${state.sort.order}
            &page=${pageNumber ? (pageNumber - 1) : (state.pageStatus.pageNumber - 1)}
            ${state.filter.filter.partnerId === 0 || state.filter.filter.partnerId == undefined ? '' : `&partner=${state.filter.filter.partnerId}`}
            ${state.filter.filter.roleId === 0 || state.filter.filter.roleId == undefined ? '' : `&partner=${state.filter.filter.roleId}`}`).trim().replace(/\s+(\&)/g, '&')

      const result = await UserService.searchUsers(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentUsers = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          commit('setPageStatus', pageStatus)
          commit('setCurrentUsers', currentUsers)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentUsers', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async orderUsers ({ commit, state }, data: SortState) {
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${data.sortfield},${data.order}
            &page=${(state.pageStatus.pageNumber - 1)}
            ${state.filter.filter.partnerId === 0 || state.filter.filter.partnerId == undefined ? '' : `&partner=${state.filter.filter.partnerId}`}
            ${state.filter.filter.roleId === 0 || state.filter.filter.roleId == undefined ? '' : `&partner=${state.filter.filter.roleId}`}`).trim().replace(/\s+(\&)/g, '&')

      const result = await UserService.searchUsers(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentUsers = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          const sortStatus = {
            sortfield: data.sortfield,
            order: data.order
          }
          commit('setSortStatus', sortStatus)
          commit('setPageStatus', pageStatus)
          commit('setCurrentUsers', currentUsers)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentUsers', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async searchUsers ({ commit }, filter: UsersFilterState) {
    commit('processInProgress', null, { root: true })
    const search = (`${filter.term === '' || filter.term === undefined ? '' : `query=${filter.term}`}
        ${filter.filter.partnerId !== 0 ? `&partner=${filter.filter.partnerId}` : ''}
        ${filter.filter.roleId !== 0 ? `&role=${filter.filter.roleId}` : ''}`).trim().replace(/\s+(\&)/g, '&')
    const result = await UserService.searchUsers(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.results.length > 0) {
        const currentUsers = result.data.results
        const status = {
          term: filter.term,
          filter: {
            roleId: filter.filter.roleId,
            partnerId: filter.filter.partnerId
          }
        }
        const pageStatus = {
          pageNumber: (result.data.currentPageable.pageNumber + 1),
          pageSize: result.data.currentPageable.pageSize,
          totalPages: result.data.totalPages,
          total: result.data.totalNumberOfElements
        }
        commit('setPageStatus', pageStatus)
        commit('setFilterStatus', status)
        commit('setCurrentUsers', currentUsers)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setCurrentUsers', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async lookupCustomer ({ commit }, accontNumber: string) {
    commit('processInProgress', null, { root: true })

    const result = await UserService.lookupCustomer(accontNumber)
    if (result && result.status === 200) {
      if (result.data) {
        const customer = result.data
        commit('setCustomer', customer)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setCustomer', null)
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('setCustomer', null)
      commit('processError', result, { root: true })
    }
  },

  async loadCsv ({ commit, state }) {
    commit('processInProgress', null, { root: true })
    const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
        ${state.filter.filter.partnerId === 0 || state.filter.filter.partnerId == undefined ? '' : `&partner=${state.filter.filter.partnerId}`}
        ${state.filter.filter.roleId === 0 || state.filter.filter.roleId == undefined ? '' : `&partner=${state.filter.filter.roleId}`}`).trim().replace(/\s+(\&)/g, '&')

    const result = await UserService.loadCsv(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.length > 0) {
        const data = result.data
        commit('setUsers', data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setUsers', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  }
}

import { render, staticRenderFns } from "./main.vue?vue&type=template&id=cd6133d2"
import script from "./main.vue?vue&type=script&lang=ts"
export * from "./main.vue?vue&type=script&lang=ts"
import style0 from "./main.vue?vue&type=style&index=0&id=cd6133d2&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/app/components/modal/Modal.ts').default})

import { PageState, SortState } from '~/types/state'
import { Values, Category, PartnerId } from '~/types'

export interface Reward {
    id: number | null;
    valueType: string;
    fulfillmentType: string;
    name: string;
    value: Values;
    validityPeriodinDays: number;
    activeDate: number;
    useByDate: number;
    inactiveDate: number;
    published: boolean;
    inventory: number;
    useInventory: boolean;
    partner: PartnerId;
    category: Category;
    createdOn: number;
    updatedOn: number;
    description: string;
    shortTerms: string;
    longTerms: string;
    isDeleted: boolean;
    image: File | null;
    blinkSkyCode: null | string;
    blinkSkyValue: number | null;
    status: string;
    giftMeValue: number;
    giftMeCode: number;
}

export interface RewardUpdate {
    id?: number | null;
    valueType: string;
    fulfillmentType: string;
    name: string;
    value: number;
    valueId? : number;
    points: number;
    cost: number;
    validityPeriodinDays: number;
    startDate: number;
    endDate: number;
    useByDate: number;
    activeDate: number;
    inactiveDate: number;
    published: string;
    inventory: number;
    useInventory: string;
    partner: number;
    category: number;
    description: string;
    shortTerms: string;
    longTerms: string;
    image: File | null;
    blinkSkyCode: string;
    blinkSkyValue: number | null;
    status: string;
    giftMeValue: number;
    giftMeCode: number;
}

export interface BulkRedeemReward {
    rewardId: number;
    formData: FormData;
}

export interface CreateRewardState {
    reward: RewardUpdate;
    value: Values;
}

export interface UpdateRewardState {
    id: number;
    reward: RewardUpdate;
    value: Values;
}

export interface InitRewardState {
    rewards: Array < Reward > ;
    currentRewards: Array < Reward > ;
    filter: RewardFilterState;
    sort: SortState;
    pageStatus: PageState;
    rewardRedeemResponse: RewardRedeemResponse | null;
    blinkSkyRewards: Array < BlinkSkyReward >;
    statuses: string[];
    giftMeCatalog: Array<GiftMeCatalogResponse>;
}

export interface RewardRedeemResponse {
    status : number ;
    message : string;
    success : false;
}

export interface RewardFilterState {
    term: string,
    filter: {
        status: string;
        partnerId: number;
        category: number;
        startDate: string;
        endDate: string;
        dateBy: string;
    };
}

export interface RewardStatus {
    label: string;
    state: boolean | string;
}

export interface BlinkSkyRewardResponse {
    d: Array < BlinkSkyReward >;
}

export interface GiftMeCatalogResponse {
    logo_path: string;
    min_amount: number;
    fixed_fee: number;
    handle: string;
    percentage_fee: number;
    category_id: number;
    description: string | null;
    terms: string | null,
    name: string;
    max_amount: number;
    id: number;
    expires_in: string;
    example_calculation: string;
    locations: Array<GiftMeCatalogLocation>;
    value: string;
    values: string[];
}

export interface GiftMeCatalogLocation {
    street_address: string;
    country: string;
    city: string;
    name: string;
    id: number;
    team_id: number;
}

export interface BlinkSkyReward {
    caption: string;
    captionLower: string;
    code: string;
    color: string;
    currency: string;
    data: string;
    desc: string;
    disclosures: string;
    discount: number;
    domain: string;
    fee: string;
    fontcolor: string;
    is_variable: false
    iso: string;
    logo: string;
    max_range: number;
    min_range: number;
    sendcolor: string;
    value: string;
    __type: string;
    values: string[];
    blinkSkyValue: number;
}

export enum RewardState {
    ALL = 'All',
    PUBLISHED = 'Published',
    NOT_PUBLISHED = 'Not Published',
}

export enum RewardDateBy {
    CREATED = 'created',
    MODIFIED = 'modified',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    USEBY = 'useBy'
}

export enum InventoryState {
    USE = 'Yes',
    DONT_USE = 'No'
}

export enum ValueType {
    STATIC = 'static'
}

export enum FulfillmentType {
    VOUCHER = 'voucher',
    TANGO = 'tango',
    TOPUP = 'topup',
    CHARITY = 'charity',
    BLINK_SKY = 'blink_sky',
    GIFT_ME = 'gift_me'
}

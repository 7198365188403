import { GetterTree, ActionTree, MutationTree } from 'vuex'
import moment from 'moment-timezone'
import { RootState, PageState, SortState } from '~/types/state'
import { InitPartnerState, Partner, PartnerUpdate, UpdatePartnerState, PartnerFilterState } from '~/types/partners'
import { PartnerService } from '~/services'

export const state = (): InitPartnerState => ({
  partners: [],
  currentPartners: [],
  filter: {
    term: ''
  },
  sort: {
    sortfield: 'name',
    order: 'desc'
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0
  }
})

export const getters: GetterTree < InitPartnerState, RootState > = {
  partners: state => ((state.partners).filter(f => !f.isDeleted ? f : false).map((elem: Partner) => {
    return ({
      id: elem.id,
      name: elem.name,
      emailAddress: elem.emailAddress,
      description: elem.description,
      longTerms: elem.longTerms,
      shortTerms: elem.shortTerms,
      createdOn: elem.createdOn === null ? '' : moment(elem.createdOn).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.updatedOn === null ? '' : moment(elem.updatedOn).tz('Jamaica', true).format('MMM-DD-YY'),
      image: elem.image ? elem.image : null
    })
  })),
  partnersDropDown: state => ((state.partners).filter(f => !f.isDeleted ? f : false).map((elem: Partner) => {
    return ({
      id: elem.id,
      name: elem.name
    })
  })).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1),
  currentPartners: state => ((state.currentPartners).filter(f => !f.isDeleted ? f : false).map((elem: Partner) => {
    return ({
      id: elem.id,
      name: elem.name,
      emailAddress: elem.emailAddress,
      description: elem.description,
      longTerms: elem.longTerms,
      shortTerms: elem.shortTerms,
      createdOn: elem.createdOn === null ? '' : moment(elem.createdOn).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.updatedOn === null ? '' : moment(elem.updatedOn).tz('Jamaica', true).format('MMM-DD-YY'),
      image: elem.image ? elem.image : null
    })
  })),
  pageStatus: (state) => {
    return state.pageStatus
  }
}

export const mutations: MutationTree < InitPartnerState > = {
  setPartners (state: InitPartnerState, partners: Array < Partner >): void {
    state.partners = [...partners]
  },
  setCurrentPartners (state: InitPartnerState, currentPartners: Array < Partner >): void {
    state.currentPartners = [...currentPartners]
  },
  reset (state: InitPartnerState): void {
    state.partners = []
    state.currentPartners = []
    state.pageStatus.pageNumber = 1
    state.pageStatus.pageSize = 0
    state.pageStatus.totalPages = 0
    state.pageStatus.total = 0
  },
  setFilterStatus (state: InitPartnerState, status: PartnerFilterState): void {
    state.filter.term = status.term
  },
  setSortStatus (state: InitPartnerState, status: SortState): void {
    state.sort.sortfield = status.sortfield
    state.sort.order = status.order
  },
  setPageStatus (state: InitPartnerState, status: PageState): void {
    state.pageStatus.pageNumber = status.pageNumber
    state.pageStatus.pageSize = status.pageSize
    state.pageStatus.totalPages = status.totalPages
    state.pageStatus.total = status.total
  }
}

export const actions: ActionTree < InitPartnerState, RootState > = {

  async createPartners ({ commit, dispatch, state }, createRequest: PartnerUpdate) {
    commit('processInProgress', null, { root: true })

    try {
      const result = await PartnerService.createPartner(createRequest)
      if (result.status === 200) {
        await dispatch('loadCurrentPartners', { pageStatus: state.pageStatus, sort: state.sort })
        commit('processSuccess', result.status, { root: true })
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async updatePartners ({ commit, state }, data: UpdatePartnerState) {
    commit('processInProgress', null, { root: true })

    const currentPartners = state.currentPartners.slice()
    try {
      const result = await PartnerService.editPartner(data.id, data.partner)

      if (result.status === 200) {
        if (result.data !== undefined) {
          const list = currentPartners.map((elem:Partner) => elem.id === result.data.id ? elem = result.data : elem)
          commit('setCurrentPartners', list)
          commit('processSuccess', result.status, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async deletePartners ({ commit, state }, data:any) {
    commit('processInProgress', null, { root: true })

    const currentPartners = state.currentPartners.slice()
    try {
      const result = await PartnerService.deletePartner(data.id)
      if (result.status === 200) {
        const list = currentPartners.filter((elem:Partner) => elem.id === data.id ? false : elem)
        commit('processSuccess', result.status, { root: true })
        commit('setCurrentPartners', list)
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadPartners ({ commit }) {
    commit('processInProgress', null, { root: true })
    const result = await PartnerService.getAllPartners()
    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setPartners', result.data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('setPartners', [])
      commit('processSuccess', 204, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  },

  async loadCurrentPartners ({ commit, state }, pageNumber) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${state.sort.sortfield},${state.sort.order}
            &page=${pageNumber ? (pageNumber - 1) : (state.pageStatus.pageNumber - 1)}`).trim().replace(/\s+(\&)/g, '&')

      const result = await PartnerService.searchPartners(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentPartners = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          commit('setPageStatus', pageStatus)
          commit('setCurrentPartners', currentPartners)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentPartners', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async orderPartners ({ commit, state }, data: SortState) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${data.sortfield},${data.order}
            &page=${(state.pageStatus.pageNumber - 1)}`).trim().replace(/\s+(\&)/g, '&')

      const result = await PartnerService.searchPartners(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentPartners = result.data.results
          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          const sortStatus = {
            sortfield: data.sortfield,
            order: data.order
          }
          commit('setSortStatus', sortStatus)
          commit('setPageStatus', pageStatus)
          commit('setCurrentPartners', currentPartners)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentPartners', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async searchPartners ({ commit }, filter: PartnerFilterState) {
    commit('processInProgress', null, { root: true })

    const search = (`${filter.term === '' || filter.term === undefined ? '' : `query=${filter.term}`}`).trim().replace(/\s+(\&)/g, '&')

    const result = await PartnerService.searchPartners(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.results.length > 0) {
        const currentPartners = result.data.results
        const status = {
          term: filter.term
        }
        const pageStatus = {
          pageNumber: (result.data.currentPageable.pageNumber + 1),
          pageSize: result.data.currentPageable.pageSize,
          totalPages: result.data.totalPages,
          total: result.data.totalNumberOfElements
        }
        commit('setPageStatus', pageStatus)
        commit('setFilterStatus', status)
        commit('setCurrentPartners', currentPartners)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setCurrentPartners', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadCsv ({ commit, state }) {
    commit('processInProgress', null, { root: true })

    const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}`).trim().replace(/\s+(\&)/g, '&')
    const result = await PartnerService.loadCsv(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.length > 0) {
        const data = result.data
        commit('setPartners', data)
        commit('resetAPIProcessState', null, { root: true })
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setPartners', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  }
}

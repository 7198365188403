import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState, PageState, SortState } from '~/types/state'
import { InitPromoClaimState, PromoClaim } from '~/types/promoClaim'
import { PromoClaimService } from '~/services'

export const state = (): InitPromoClaimState => ({
  promoClaims: [],
  sort: {
    sortfield: '',
    order: 'desc'
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0
  }
})

export const getters: GetterTree<InitPromoClaimState, RootState> = {
  promoClaims: state => state.promoClaims,
  sort: state => state.sort,
  pageStatus: state => state.pageStatus
}

export const mutations: MutationTree<InitPromoClaimState> = {
  setPromoClaims (state, promoClaims: PromoClaim[]): void {
    state.promoClaims = [...promoClaims]
  },
  setSortStatus (state, status: SortState): void {
    state.sort = { ...status }
  },
  setPageStatus (state, status: PageState): void {
    state.pageStatus = { ...status }
  }
}

export const actions: ActionTree<InitPromoClaimState, RootState> = {
  async loadPromoClaims ({ commit }, data: {promoCode: string}) {
    commit('processInProgress', null, { root: true })

    const result = await PromoClaimService.getAllClaimsForCode(data.promoCode)
    if (result.success) {
      commit('setPromoClaims', result.data)
      commit('processSuccess', 200, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    } else {
      commit('processError', result.error.error.message, { root: true })
    }
  },

  async loadCsv ({ commit }, data: { promoCode: string }) {
    commit('processInProgress', null, { root: true })

    const result = await PromoClaimService.getAllClaimsForCode(data.promoCode)
    if (result.success) {
      commit('setPromoClaims', result.data)
      commit('processSuccess', 200, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    } else {
      commit('processError', result.error.error.message, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  }
}

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState, PageState, SortState } from '~/types/state'
import { InitVoucherSummaryState, VoucherSummaryFilterState, VoucherSummary, VoucherInitSortState } from '~/types/vouchers'
import { VoucherService } from '~/services'

export const state = (): InitVoucherSummaryState => ({
  voucherSummary: [],
  currentVoucherSummary: [],
  filter: {
    filter: {
      partnerId: 0,
      reward: 0,
      status: '',
      dateBy: '',
      startDate: '',
      endDate: ''
    }
  },
  sort: {
    sortfield: VoucherInitSortState.SORT_FIELD,
    order: VoucherInitSortState.SORT_ORDER_DESC
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0
  }
})

export const getters: GetterTree < InitVoucherSummaryState, RootState > = {
  voucherSummary: state => ((state.voucherSummary).map((elem: VoucherSummary) => {
    return ({
      status: elem.status,
      partnerName: elem.partnerName,
      rewardName: elem.rewardName,
      count: elem.count,
      sum: elem.sum
    })
  })),
  currentVoucherSummary: state => ((state.currentVoucherSummary).map((elem: VoucherSummary) => {
    return ({
      status: elem.status,
      partnerName: elem.partnerName,
      rewardName: elem.rewardName,
      count: elem.count,
      sum: elem.sum
    })
  })),
  pageStatus: (state) => {
    return state.pageStatus
  },
  filterState: (state) => {
    return state.filter
  }
}

export const mutations: MutationTree < InitVoucherSummaryState > = {
  setVoucherSummary (state: InitVoucherSummaryState, voucherSummary: Array < VoucherSummary >): void {
    state.voucherSummary = [...voucherSummary]
  },
  setCurrentVoucherSummary (state: InitVoucherSummaryState, currentVoucherSummary: Array < VoucherSummary >): void {
    state.currentVoucherSummary = [...currentVoucherSummary]
  },
  reset (state: InitVoucherSummaryState): void {
    state.voucherSummary = []
    state.currentVoucherSummary = []
    state.pageStatus.pageNumber = 1
    state.pageStatus.pageSize = 0
    state.pageStatus.totalPages = 0
    state.pageStatus.total = 0
  },
  setFilterStatus (state: InitVoucherSummaryState, status: VoucherSummaryFilterState): void {
    state.filter.filter.partnerId = status.filter.partnerId
    state.filter.filter.reward = status.filter.reward
    state.filter.filter.status = status.filter.status
    state.filter.filter.startDate = status.filter.startDate
    state.filter.filter.endDate = status.filter.endDate
    state.filter.filter.dateBy = status.filter.dateBy
  },
  setSortStatus (state: InitVoucherSummaryState, status: SortState): void {
    state.sort.sortfield = status.sortfield
    state.sort.order = status.order
  },
  setPageStatus (state: InitVoucherSummaryState, status: PageState): void {
    state.pageStatus.pageNumber = status.pageNumber
    state.pageStatus.pageSize = status.pageSize
    state.pageStatus.totalPages = status.totalPages
    state.pageStatus.total = status.total
  }
}

export const actions: ActionTree < InitVoucherSummaryState, RootState > = {

  async loadCurrentVoucherSummary ({ commit, state }, data) {
    commit('processInProgress', null, { root: true })

    try {
      const search = (`page=${data.pageNumber ? (data.pageNumber - 1) : (state.pageStatus.pageNumber - 1)}
            ${data.partnerId !== 0 ? `&partner=${data.partnerId}` : ''}
            ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
            ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
            ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
            ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
            ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}`).trim().replace(/\s+(\&)/g, '&')

      const result = await VoucherService.searchVoucherSummary(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentVouchers = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          commit('setPageStatus', pageStatus)
          commit('setCurrentVoucherSummary', currentVouchers)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentVoucherSummary', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async orderVoucherSummary ({ commit, state }, data: SortState) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`page=${(state.pageStatus.pageNumber - 1)}
            ${data.partnerId && data.partnerId !== 0 ? `&partner=${data.partnerId}` : ''}
            ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
            ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
            ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
            ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
            ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}`).trim().replace(/\s+(\&)/g, '&')

      const result = await VoucherService.searchVoucherSummary(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentVouchers = result.data.results
          const sortStatus = {
            sortfield: data.sortfield,
            order: data.order
          }

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          commit('setSortStatus', sortStatus)
          commit('setPageStatus', pageStatus)
          commit('setCurrentVoucherSummary', currentVouchers)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentVoucherSummary', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async searchVoucherSummary ({ commit }, filter: VoucherSummaryFilterState) {
    commit('processInProgress', null, { root: true })

    const search = (`${filter.filter.partnerId !== 0 ? `&partner=${filter.filter.partnerId}` : ''}
        ${filter.filter.reward !== 0 ? `&reward=${filter.filter.reward}` : ''}
        ${filter.filter.startDate !== '' ? `&startDate=${filter.filter.startDate}` : ''}
        ${filter.filter.endDate !== '' ? `&endDate=${filter.filter.endDate}` : ''}
        ${filter.filter.dateBy !== '' ? `&dateBy=${filter.filter.dateBy}` : ''}
        ${filter.filter.status !== '' ? `&status=${filter.filter.status}` : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await VoucherService.searchVoucherSummary(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      const status = {
        filter: {
          partnerId: filter.filter.partnerId,
          reward: filter.filter.reward,
          status: filter.filter.status,
          startDate: filter.filter.startDate,
          endDate: filter.filter.endDate,
          dateBy: filter.filter.dateBy
        }
      }

      if (result.data.results.length > 0) {
        const currentVoucherSummary = result.data.results
        const pageStatus = {
          pageNumber: (result.data.currentPageable.pageNumber + 1),
          pageSize: result.data.currentPageable.pageSize,
          totalPages: result.data.totalPages,
          total: result.data.totalNumberOfElements
        }
        commit('setPageStatus', pageStatus)
        commit('setCurrentVoucherSummary', currentVoucherSummary)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setCurrentVoucherSummary', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
      commit('setFilterStatus', status)
    } else {
      commit('processError', result, { root: true })
      throw new Error(result)
    }
  },

  async loadCsv ({ commit, state }) {
    commit('processInProgress', null, { root: true })
    const search = (`${state.filter.filter.partnerId !== 0 ? `&partner=${state.filter.filter.partnerId}` : ''}
        ${state.filter.filter.reward !== 0 ? `&reward=${state.filter.filter.reward}` : ''}
        ${state.filter.filter.startDate !== '' ? `&startDate=${state.filter.filter.startDate}` : ''}
        ${state.filter.filter.endDate !== '' ? `&endDate=${state.filter.filter.endDate}` : ''}
        ${state.filter.filter.dateBy !== '' ? `&dateBy=${state.filter.filter.dateBy}` : ''}
        ${state.filter.filter.status !== '' ? `&status=${state.filter.filter.status}` : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await VoucherService.loadCsvSummary(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.length > 0) {
        const data = result.data
        commit('setVoucherSummary', data)
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setVoucherSummary', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  }
}

import { REGEX_ROLES, PAGE_ACCESS } from '~/core/constants'

export default function ({ store, route, redirect }: any) {
  // If the user is not authenticated redirect to the login page
  if (!store.state.auth) {
    return redirect('/login')
  } else if (store.state.auth) {
    const role: string = store.state.account.authorities.find((elem: string) => elem.match(REGEX_ROLES) ? elem : '')

    if (store.state.account.authorities.length > 0) {
      !PAGE_ACCESS[role].routes.includes(route.path)
        ? redirect(PAGE_ACCESS[role].routes[0])
        : false
    }
  }
}

export * from './state'
export * from './rewards'
export * from './promoCode'
export * from './promoClaim'
export * from './api'
export * from './vouchers'

export interface Account {
  authorities: Array<string>;
}

export interface Sort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
}

export interface PagedResponse<T> {
  totalNumberOfElements: number;
  totalPages: number;
  results: T[];
  nextPageable: string;
  previousPageable: string;
  currentPageable: Pageable;
}

export interface UserProfile {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string
  partner?:{
    id:number;
    name:string;
  }
  role:{
    id:number;
    name:string;
  }
}

export interface UpdatePassword {
  currentPassword: string;
  password: string;
}

export interface Credentials {
  accessToken: string;
  refreshToken: string;
}

export interface ResetPassword {
  token: string;
  password: string;
  passwordConfirm: string;
}

export interface Reward {
  id: number;
  name: string;
  image: string;
  category: string;
}

export interface Values {
  id?: number;
  name?: string;
  value: number;
  points: number | null;
  cost: number | null;
}

export interface Category {
  id: number;
  name: string;
  image: number;
}

export interface VoucherAccount {
  id: number;
  firstName: string;
  lastName: string;
  accountNumber: string;
}

export interface ColumnHeadings {
  key: string;
  label: string;
  isDescending: boolean;
  sortable: boolean;
  isVisible: boolean;
}

export interface Actions {
  key: string;
  label: string;
  action: string;
  icon: string;
  authority:string;
  workFlow?:Array<string>;
  withoutSelection?: boolean;
  role?: Array<string>;
}

export interface PartnerId {
  id: number;
  name: string;
  image: File;
}

export interface EventBusParams {
  state: boolean;
  data: any;
}

export interface PageLevelAccessConfig {
  routes: Array<string>;
}

export interface PageAccess {
  [id: string]:PageLevelAccessConfig
}

export enum Authorities {
  READ_PARTNER = 'read:partner',
  SEARCH_PARTNER ='search:partner',
  WRITE_REWARDS_CATEGORY = 'write:rewards-category',
  WRITE_USER = 'write:user',
  APPROVE_VOUCHER = 'approve:voucher',
  READ_OAUTH = 'read:oauth',
  READ_REWARD_VALUE = 'read:reward-value',
  READ_ACCOUNT = 'read:account',
  WRITE_PARTNER = 'write:partner',
  WRITE_REWARD = 'write:reward',
  WRITE_REWARD_VALUE = 'write:reward-value',
  READ_USER = 'read:user',
  SEARCH_USER = 'read:user',
  MODIFY_VOUCHER = 'modify:voucher',
  DISTRIBUTE_VOUCHER = 'distribute:voucher',
  READ_REWARDS_CATEGORY = 'read:rewards-category',
  WRITE_VOUCHER = 'write:voucher',
  READ_VOUCHER = 'read:voucher',
  CANCEL_VOUCHER = 'cancel:voucher',
  ADMIN_SPOONITY = 'admin:spoonity',
  RESEND_VOUCHER = 'resend:voucher',
  WRITE_ACCOUNT = 'write:account',
  CLOSE_VOUCHER = 'close:voucher',
  READ_REWARD = 'read:reward',
  REDEEM_REWARD = 'redeem:reward',
  READ_PROMO_CODE = 'read:promo-code',
  WRITE_PROMO_CODE = 'write:promo-code',
  READ_PROMO_CLAIM = 'read:promo-claim',
  WRITE_PROMO_CLAIM = 'write:promo-claim',
  DELETE_PROMO_CLAIM = 'delete:promo-claim',
  READ_NOTIFICATION_TRIGGER = 'read:email-trigger',
  MODIFY_NOTIFICATION_TRIGGER = 'modify:email-trigger',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ACCOUNTANT = 'ROLE_ACCOUNTANT',
  ROLE_CSR = 'ROLE_CSR',
  ROLE_GRACE_PARTNER_ADMIN = 'ROLE_GRACE_PARTNER_ADMIN',
  ROLE_PARTNER_ADMIN = 'ROLE_PARTNER_ADMIN',
  ROLE_PARTNER_STAFF = 'ROLE_PARTNER_STAFF',

  // Store
  WRITE_STORE= 'write:store',
  SEARCH_STORE= 'search:store',
  READ_STORE= 'read:store',
  MODIFY_STORE= 'modify:store',
}

export enum Roles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ACCOUNTANT = 'ROLE_ACCOUNTANT',
  ROLE_CSR = 'ROLE_CSR',
  ROLE_GRACE_PARTNER_ADMIN = 'ROLE_GRACE_PARTNER_ADMIN',
  ROLE_PARTNER_ADMIN = 'ROLE_PARTNER_ADMIN',
  ROLE_PARTNER_STAFF = 'ROLE_PARTNER_STAFF',
}

export enum RoleIds {
  ROLE_ADMIN = 1,
  ROLE_ACCOUNTANT = 4,
  ROLE_CSR = 5,
  ROLE_GRACE_PARTNER_ADMIN = 8,
  ROLE_PARTNER_ADMIN = 6,
  ROLE_PARTNER_STAFF = 7,
}

export enum Routes {
  DASHBOARD = '/',
  MANAGE_USERS= '/manage-users',
  MANAGE_REWARDS = '/manage-rewards',
  MANAGE_REWARD_PARTNER = '/manage-reward-partner',
  MANAGE_REWARD_CATEGORY = '/manage-reward-category',
  MANAGE_PROMO_CODES = '/manage-promo-codes',
  MANAGE_VOUCHER = '/manage-voucher',
  MANAGE_STORE = '/manage-store',
  MANAGE_NOTIFICATIONS = '/manage-notifications',
  APPROVE_VOUCHER = '/approve-voucher',
  VOUCHER_HISTORY = '/voucher-history',
  VOUCHER_SUMMARY = '/voucher-summary',
  LOGIN = '/login',
}

export enum StoreNames {
  STORE_PARTNER = 'partners',
  STORE_USERS= 'users',
  STORE_REWARDS = 'rewards',
  STORE_CATEGORY = 'rewardCategories',
  STORE_VOUCHER = 'vouchers',
  STORE_NOTIFICATIONS = 'notifications',
  STORE_VOUCHER_DETAILS = 'voucherDetails',
  STORE_VOUCHER_SUMMARY = 'voucherSummary',
  LOCALSTORAGE_PROFILE = 'gk_user_profile',
}

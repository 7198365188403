import { AxiosError } from 'axios'
import { BaseService } from './base.service'
import { axios } from '~/lib/axios'
import { APIResponse } from '~/types/api'
import { PagedResponse, PromoCode, PromoCodeRequestBody } from '~/types'

class PromoCodeService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------
  private readonly BASE_URL = '/api/v1/promo'
  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------
  public async getPromoCodeDetails (code: string): Promise<APIResponse<PromoCode>> {
    let response: APIResponse<PromoCode>
    const endpoint = this.configs.API_HOST + this.BASE_URL
    try {
      const result = await axios.get(`${endpoint}/${code}`)
      response = { success: true, data: result.data }
    } catch (error: any) {
      response = { success: false, error: (error as AxiosError).response?.data }
    }
    return response
  }

  public async getAllPromoCodes (): Promise<APIResponse<PromoCode[]>> {
    let response: APIResponse<PromoCode[]>
    const endpoint = this.configs.API_HOST + this.BASE_URL

    try {
      const result = await axios.get(`${endpoint}`)
      response = { success: true, data: result.data }
    } catch (error: any) {
      response = { success: false, error: (error as AxiosError).response?.data }
    }
    return response
  }

  public async createPromoCode (requestBody: PromoCodeRequestBody): Promise<APIResponse<PromoCode>> {
    let response: APIResponse<PromoCode>
    const endpoint = this.configs.API_HOST + this.BASE_URL

    try {
      const result = await axios.post(`${endpoint}`, requestBody)
      response = { success: true, data: result.data }
    } catch (error: any) {
      response = { success: false, error: (error as AxiosError).response?.data }
    }
    return response
  }

  public async updatePromoCode (code: string, requestBody: PromoCodeRequestBody): Promise<APIResponse<PromoCode>> {
    let response: APIResponse<PromoCode>
    const endpoint = this.configs.API_HOST + this.BASE_URL

    try {
      const result = await axios.put(`${endpoint}/${code}`, requestBody)
      response = { success: true, data: result.data }
    } catch (error: any) {
      response = { success: false, error: (error as AxiosError).response?.data }
    }
    return response
  }

  public async deletePromoCode (shortCode: string): Promise<APIResponse<PromoCode>> {
    let response: APIResponse<PromoCode>
    const endpoint = this.configs.API_HOST + this.BASE_URL

    try {
      const result = await axios.delete(`${endpoint}/${shortCode}`)
      response = { success: true, data: result.data }
    } catch (error: any) {
      response = { success: false, error: (error as AxiosError).response?.data }
    }
    return response
  }

  public async searchPromoCodes (searchString: string, shouldPage = true): Promise<APIResponse<PagedResponse<PromoCode>>> {
    let response: APIResponse<PagedResponse<PromoCode>>
    const endpoint = this.configs.API_HOST + this.BASE_URL

    try {
      const result = await axios.get(`${endpoint}/search?shouldPage=${shouldPage}&size=100&${searchString}`)
      response = { success: true, data: result.data }
    } catch (error: any) {
      response = { success: false, error: (error as AxiosError).response?.data }
    }
    return response
  }
  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
}

export default PromoCodeService

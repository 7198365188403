import { PageAccess, Routes, Roles } from '~/types'

export const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_GKVR_NUMBER = /(?![0]*$)[0-9]{8}/g
export const REGEX_CARD_PIN = /(?![0]*$)[0-9]{4}/g
export const REGEX_PASSWORD_RESET_TOKEN = /^[a-z0-9]{32}$/g
export const REGEX_PASSWORD = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
export const REGEX_ROLES = /(ROLE_)\w+/g
export const REGEX_CSV = /^.*(csv|CSV|ms-excel|MS-EXCEL)$/
export const REGEX_ALPA_NUM_SPACE = /^([a-zA-Z0-9]+(\s|-))*([a-zA-Z0-9])+$/i
export const REGEX_NUMERIC = /^([0-9])+$/i
export const REGEX_DIGITS = /^[0-9]{10,11}$/i

export enum GlobalEnum {
  STORE_NAME = 'rewards',
  AUTHORITIES_GETTER = 'authorities',
  USER_PROFILE_GETTER = 'profile',
  STRING_TYPE_CHECK = 'string'
}

export const PAGE_ACCESS: PageAccess = {
  [Roles.ROLE_ADMIN]: {
    routes: [
      Routes.MANAGE_USERS,
      Routes.MANAGE_REWARDS,
      Routes.MANAGE_REWARD_PARTNER,
      Routes.MANAGE_REWARD_CATEGORY,
      Routes.MANAGE_VOUCHER,
      Routes.APPROVE_VOUCHER,
      Routes.VOUCHER_HISTORY,
      Routes.VOUCHER_SUMMARY,
      Routes.MANAGE_STORE,
      Routes.MANAGE_PROMO_CODES,
      Routes.MANAGE_NOTIFICATIONS
    ]
  },
  [Roles.ROLE_CSR]: {
    routes: [
      Routes.MANAGE_REWARDS,
      Routes.MANAGE_PROMO_CODES,
      Routes.MANAGE_REWARD_PARTNER,
      Routes.MANAGE_REWARD_CATEGORY,
      Routes.MANAGE_VOUCHER,
      Routes.APPROVE_VOUCHER,
      Routes.VOUCHER_HISTORY,
      Routes.VOUCHER_SUMMARY
    ]
  },
  [Roles.ROLE_GRACE_PARTNER_ADMIN]: {
    routes: [
      Routes.MANAGE_REWARDS,
      Routes.MANAGE_REWARD_PARTNER,
      Routes.MANAGE_VOUCHER,
      Routes.APPROVE_VOUCHER,
      Routes.VOUCHER_HISTORY,
      Routes.VOUCHER_SUMMARY
    ]
  },
  [Roles.ROLE_PARTNER_ADMIN]: {
    routes: [
      Routes.MANAGE_REWARD_PARTNER,
      Routes.MANAGE_VOUCHER,
      Routes.APPROVE_VOUCHER,
      Routes.VOUCHER_HISTORY,
      Routes.VOUCHER_SUMMARY
    ]
  },
  [Roles.ROLE_PARTNER_STAFF]: {
    routes: [
      Routes.MANAGE_REWARD_PARTNER,
      Routes.APPROVE_VOUCHER
    ]
  },
  [Roles.ROLE_ACCOUNTANT]: {
    routes: [
      Routes.APPROVE_VOUCHER,
      Routes.MANAGE_VOUCHER,
      Routes.VOUCHER_HISTORY,
      Routes.VOUCHER_SUMMARY
    ]
  }

}

export const CUSTOMER_PORTAL_URL : { [key: string]: {customerPortal: string}} = {
  LOCAL: {
    customerPortal: 'http://localhost:3000'
  },
  DEV: {
    customerPortal: 'https://devcust.gkvrapi.com'
  },
  TEST: {
    customerPortal: 'https://testcust.gkvrapi.com'
  },
  STAGE: {
    customerPortal: 'https://stagecust.gkvrapi.com'
  },
  PROD: {
    customerPortal: 'https://www.gkvaluerewards.com'
  }
}

export const AFFILIATED_LINKS: { [key: string]: {etl: string; spoonity: string}} = {
  STAGE: {
    etl: 'https://stageetl.gkvrapi.com',
    spoonity: 'https://dashboard-staging-gracekennedy.spoonity.com/login'
  },
  PROD: {
    etl: 'https://etl.gkvrapi.com',
    spoonity: 'https://dashboard-gracekennedy.spoonity.com/login'
  }
}

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import moment from 'moment-timezone'
import { RootState, PageState, SortState } from '~/types/state'
import { InitStoreState, Store, StoreFilterState, UpdateStoreState } from '~/types/stores'
import { StoreService } from '~/services'

export const state = (): InitStoreState => ({
  stores: [],
  currentStores: [],
  filter: {
    term: '',
    partnerId: 0
  },
  sort: {
    sortfield: 'name',
    order: 'desc'
  },
  pageStatus: {
    pageNumber: 1,
    pageSize: 0,
    totalPages: 0,
    total: 0
  }
})

export const getters: GetterTree < InitStoreState, RootState > = {
  stores: state => ((state.stores).filter(f => !f.isDeleted ? f : false).map((elem: Store) => {
    return ({
      id: elem.id,
      name: elem.name,
      createdOn: elem.createdOn === null ? '' : moment(elem.createdOn).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.updatedOn === null ? '' : moment(elem.updatedOn).tz('Jamaica', true).format('MMM-DD-YY'),
      partner: elem.partner ? elem.partner.name : null
    })
  })).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1),
  storesDropDown: state => ((state.stores).filter(f => !f.isDeleted ? f : false).map((elem: Store) => {
    return ({
      id: elem.id,
      name: elem.name
    })
  })).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1),
  currentStores: state => ((state.currentStores).filter(f => !f.isDeleted ? f : false).map((elem: Store) => {
    return ({
      id: elem.id,
      name: elem.name,
      createdOn: elem.createdOn === null ? '' : moment(elem.createdOn).tz('Jamaica', true).format('MMM-DD-YY'),
      updatedOn: elem.updatedOn === null ? '' : moment(elem.updatedOn).tz('Jamaica', true).format('MMM-DD-YY'),
      partner: elem.partner ? elem.partner.name : null,
      partnerId: elem.partner ? elem.partner.id : null
    })
  })),
  pageStatus: (state) => {
    return state.pageStatus
  }
}

export const mutations: MutationTree < InitStoreState > = {
  setStores (state: InitStoreState, stores: Array < Store >): void {
    state.stores = [...stores]
  },
  setCurrentStores (state: InitStoreState, currentStores: Array < Store >): void {
    state.currentStores = [...currentStores]
  },
  resetStoreState (state: InitStoreState): void {
    state.stores = []
    state.currentStores = []
    state.pageStatus.pageNumber = 1
    state.pageStatus.pageSize = 0
    state.pageStatus.totalPages = 0
    state.pageStatus.total = 0
  },
  setFilterStatus (state: InitStoreState, status: StoreFilterState): void {
    state.filter.term = status.term
  },
  setSortStatus (state: InitStoreState, status: SortState): void {
    state.sort.sortfield = status.sortfield
    state.sort.order = status.order
  },
  setPageStatus (state: InitStoreState, status: PageState): void {
    state.pageStatus.pageNumber = status.pageNumber
    state.pageStatus.pageSize = status.pageSize
    state.pageStatus.totalPages = status.totalPages
    state.pageStatus.total = status.total
  }
}

export const actions: ActionTree < InitStoreState, RootState > = {

  async createStores ({ commit, dispatch, state }, createRequest: Store) {
    commit('processInProgress', null, { root: true })

    try {
      const result = await StoreService.createStore(createRequest)
      if (result.status === 200) {
        await dispatch('loadCurrentStores', { pageStatus: state.pageStatus, sort: state.sort })
        commit('processSuccess', result.status, { root: true })
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async resetStores ({ commit, dispatch }, createRequest: Store) {
    commit('processInProgress', null, { root: true })
    commit('resetStoreState', null, { root: true })

    try {
      const result = await StoreService.createStore(createRequest)
      if (result.status === 200) {
        await dispatch('loadStores')
        commit('processSuccess', result.status, { root: true })
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async updateStores ({ commit, state }, data: UpdateStoreState) {
    commit('processInProgress', null, { root: true })

    const currentPartners = state.currentStores.slice()
    try {
      const result = await StoreService.editStore(data.id, data.store)

      if (result.status === 200) {
        if (result.data !== undefined) {
          const list = currentPartners.map((elem:Store) => elem.id === result.data.id ? elem = result.data : elem)
          commit('setCurrentStores', list)
          // await dispatch('loadCurrentStores', {pageStatus:state.pageStatus, sort:state.sort});
          commit('processSuccess', result.status, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async deleteStores ({ commit, state }, data:any) {
    commit('processInProgress', null, { root: true })

    const currentStores = state.currentStores.slice()
    const status = state.pageStatus
    try {
      const result = await StoreService.deleteStore(data.id)
      if (result.status === 200) {
        const list = currentStores.filter((elem:Store) => elem.id === data.id ? false : elem)
        status.total = status.total - 1
        commit('processSuccess', result.status, { root: true })
        commit('setCurrentStores', list)
        commit('setPageStatus', status)
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async loadStores ({ commit }, data: { partnerId:number }) {
    commit('processInProgress', null, { root: true })
    let result

    if (data && data.partnerId) {
      result = await StoreService.getAllStoresBasedOnPartnerId(data.partnerId)
    } else {
      result = await StoreService.getAllStores()
    }

    if (result.status === 200) {
      if (result.data.length > 0) {
        commit('setStores', result.data)
      } else {
        commit('setStores', [])
      }

      commit('processSuccess', result.status, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    } else {
      commit('setStores', [])
      commit('processSuccess', 204, { root: true })
      commit('resetAPIProcessState', null, { root: true })
    }
  },

  async loadCurrentStores ({ commit, state }, pageNumber) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${state.sort.sortfield},${state.sort.order}
            &page=${pageNumber ? (pageNumber - 1) : (state.pageStatus.pageNumber - 1)}`).trim().replace(/\s+(\&)/g, '&')

      const result = await StoreService.searchStores(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentStores = result.data.results

          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          commit('setPageStatus', pageStatus)
          commit('setCurrentStores', currentStores)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentStores', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async orderStores ({ commit, state }, data: SortState) {
    commit('processInProgress', null, { root: true })
    try {
      const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}
            &sort=${data.sortfield},${data.order}
            &partnerId=${state.filter.partnerId ? state.filter.partnerId : ''}
            &page=${(state.pageStatus.pageNumber - 1)}`).trim().replace(/\s+(\&)/g, '&')

      const result = await StoreService.searchStores(search[0] === '&' ? search.substr(1) : search)

      if (result.status === 200) {
        if (result.data.results.length > 0) {
          const currentStores = result.data.results
          const pageStatus = {
            pageNumber: (result.data.currentPageable.pageNumber + 1),
            pageSize: result.data.currentPageable.pageSize,
            totalPages: result.data.totalPages,
            total: result.data.totalNumberOfElements
          }
          const sortStatus = {
            sortfield: data.sortfield,
            order: data.order
          }
          commit('setSortStatus', sortStatus)
          commit('setPageStatus', pageStatus)
          commit('setCurrentStores', currentStores)
          commit('processSuccess', result.status, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        } else {
          commit('setCurrentStores', [])
          commit('processSuccess', 204, { root: true })
          commit('resetAPIProcessState', null, { root: true })
        }
      } else {
        commit('processError', result, { root: true })
        throw new Error(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  },

  async searchStores ({ commit }, filter: StoreFilterState) {
    commit('processInProgress', null, { root: true })

    const search = (`${filter.term === '' || filter.term === undefined ? '' : `query=${filter.term}`}${filter.partnerId ? `&partnerId=${filter.partnerId}` : ''}`).trim().replace(/\s+(\&)/g, '&')

    const result = await StoreService.searchStores(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.results.length > 0) {
        const currentStores = result.data.results
        const status: StoreFilterState = {
          term: filter.term,
          partnerId: filter.partnerId
        }
        const pageStatus = {
          pageNumber: (result.data.currentPageable.pageNumber + 1),
          pageSize: result.data.currentPageable.pageSize,
          totalPages: result.data.totalPages,
          total: result.data.totalNumberOfElements
        }
        commit('setPageStatus', pageStatus)
        commit('setFilterStatus', status)
        commit('setCurrentStores', currentStores)
        filter.updateFilter ? commit('setStores', currentStores) : null
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        filter.updateFilter ? commit('setStores', []) : null
        commit('setCurrentStores', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  },

  async loadCsv ({ commit, state }) {
    commit('processInProgress', null, { root: true })

    const search = (`${state.filter.term === '' || state.filter.term === undefined ? '' : `query=${state.filter.term}`}`).trim().replace(/\s+(\&)/g, '&')
    const result = await StoreService.loadCsv(search[0] === '&' ? search.substr(1) : search)

    if (result.status === 200) {
      if (result.data.length > 0) {
        const data = result.data
        commit('setStores', data)
        commit('resetAPIProcessState', null, { root: true })
        commit('processSuccess', result.status, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      } else {
        commit('setStores', [])
        commit('processSuccess', 204, { root: true })
        commit('resetAPIProcessState', null, { root: true })
      }
    } else {
      commit('processError', result, { root: true })
    }
  }
}
